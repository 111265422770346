import { observable, action, computed, comparer, toJS } from "mobx";
import axios from 'axios';
// import io from 'socket.io-client';
import { api } from 'Configs/api';
// import { ButtonGroup } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import Geocode from 'react-geocode';
import { version, ignore } from "mobx-sync";
import { PhoneNumberUtil, PhoneNumberType } from "google-libphonenumber";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage('he')
// const socket = io(api.my_shop_api.socketUrl);
export const my_env = api.my_shop_api;

if(my_env.debug){
    //console.log("my_env:", my_env);
}
@version('2.100')
class MyShopApiStore {

    @ignore my_env = api.my_shop_api;
    bgCodeDef = '';
    mainColorDef = 'grey';
    // mStatus = my_env.mStatus? my_env.mStatus : false;
    // MaintText = my_env.mText? my_env.mText : '';

    // @observable isPastadioroSite = false;
    @observable shopError = null;
    @observable stepNum = 1;
    @observable maxStepNum = 1;
    @observable cartPrice = 0;
    @observable cartPriceBeforeDiscount;
    @observable cartCouponDiscount;
    // @observable cartItemList = [];
    @observable cartList = [];
    @observable isEditCartPackForm = false;
    @observable isCartItemUpdating = false;
    //@ignore @observable cartItemUpdating = null;
    @observable cartItemUpdatingIdx=null;

    @computed
    get cartItemUpdating(){ 
        return (
            (this.cartItemUpdatingIdx===null || !this.thisCart.cartItemList.length)
                ?null:this.thisCart.cartItemList[this.cartItemUpdatingIdx]
        );
    }
    @observable isPackItemUpdating = false;
    @observable packItemUpdating = null;
    @observable isOpenForEdit = false; //cart item -> init open or edit open !
    @observable inAssociatedStage = false;
    @observable associatedProducts = null;
    
    @observable csrf = null;
    @ignore @observable isMaintenance = false; //flag to show "server in maintenance" in case of bad response
    @observable shopId = null;
    @observable shopName = null;
    @observable shopCode = '';
    //@ignore apiResp = null; //response from set-shop
    @observable shopData = null; //data from apiResp
    @computed get shop() { 
        let shop = this.shopData?.shop; 
        if (shop && !shop.isBusiness && shop.withoutPrices) {
            shop.isBusiness = shop.withoutPrices; 
        }
        return shop; 
    };    //shop from shopData
    @observable shopLatLng = null;    //shop coords
    @observable deliveryLatLng = null;    //user coords
    @observable deliveryCost = 0;    //delivery cost
    @observable deliveries = null;    //delivery options to choose by user
    deliveryType = null;    //delivery type chosen by user
    @observable categories = null;    //categories from shop
    //@ignore @observable itemListResp = null;    //resp for items from category with ActiveSideMenuIdx
    //@observable itemList = null;    //itemListResp.categoryProducts[cat]
    @observable itemListAll = {}; // item list for all categories
    @computed
    get itemList() {
        return this.itemListAll && this.itemListAll[this.shopId] && this.itemListAll[this.shopId][this.selectedCategoryCode] 
            ? this.itemListAll[this.shopId][this.selectedCategoryCode] : null
    }
    @observable shopsData = null;    // get-shops resp data
    //@observable isSingleStore = null;
    
    //isSingleStore true is only when shopsData.shops is an empty array
    @computed
    get isSingleStore() { 
        return !this.shopsData?.shops?.length || false;
    }  
    @observable history = null; //local? history of previous purchases

    @observable init = null;
    @observable ActiveSideMenuIdx = 0;
    @observable MaxSideMenuIdx = 0;
    @observable bgCode = this.bgCodeDef;
    @observable bgCodeWay = '';
    @observable fileBg = '';
    //@observable selectedCategoryIdx = 0;
    
    @observable selectedCategories = {};
    @observable selectedCategoryCode = null;

    @action 
    setSelectedCategoryCode (code) {
        if (!this.shopId || !code?.length) return; 
        if (!this.categories.some(code => code === this.selectedCategoryCode) && this.categories.length) {
            this.selectedCategoryCode = this.categories[0].codename;
        } else {
            this.selectedCategoryCode = code
        }
        this.selectedCategories[this.shopId] = this.selectedCategoryCode;
    }

    @observable selectedSubCategoryCodename = null;

    @observable categoryPage = 1;
    @observable categoryPageCount = 1;

    @ignore @observable isProductsLoading = false;
    @ignore isShopsLoading = false;

    @ignore isShopLoading = false;

    @observable cardNumber = '';
    @observable otherPaymentType = '';
    @observable paymentProcessing = false;

    @action
    setPaymentProcessing = (value) => {
        this.paymentProcessing = value;
    }

    @action
    setShopError = (value) => {
        this.shopError = value;
    }

    @action
    resetShopError = () => {
        this.shopError = null;
    }

    @observable userParams = {
        name: '',
        country: {},
        phone: '',
        streetName: '',
        streetNumber: '',
        floorNumber: '',
        entranceCode: '',
        apartmentNumber: '',
        state: '',
        zipCode: '',
        city: '',
        email: ''
    };

    @observable creditParams = {
        token: '',
        id: '',
        cc_number: '',
        cvv: '',
        month: '',
        year: '',
        track2: ''
    };

    @observable lng;
    
    @action setLng = (lng) => {
        this.lng=lng;
    } 

    @observable blockClosing = false;

    @action setBlockClosing = () => {
        this.blockClosing = true;
    }

    @action clearBlockClosing = () => {
        this.blockClosing = false;
    }

    @action setCardNumber = (cardNumber) => {
        this.cardNumber = cardNumber;
    }
    @action setOtherPaymentType = (paymentType) => {
        this.otherPaymentType = paymentType;
    }

    @action setAssociatedStage = (flag) => {
        this.inAssociatedStage = flag;
    }

    @observable userBudget = null;

    @action
    setUserBudget = (sum) => {
        this.userBudget = sum;
    }

    checkAssociatedProductsInCart = () => {
        //  get the cart items
        const items = this.thisCart.cartItemList;
        //  create a variable to hold the associated products
        let shopProductsAssociatedIds = [];
        let cartIds = [];

        //  go over all the cart items
        for(let cartItem of items) {
            //  save the cart item ID
            cartIds.push(cartItem.item.id);

            //  if the cartItem has associated products
            if (cartItem.item.shopProductsAssociatedIds?.length) {
                //  go over each associated product and add it to the list if not already there
                for(let assocItem of cartItem.item.shopProductsAssociatedIds) {
                    if (!shopProductsAssociatedIds.includes(assocItem)) {
                        shopProductsAssociatedIds.push(assocItem);
                    }
                }
            }
        }

        //  check if there are any associated items already in the cart
        for (let assocItem of cartIds) {
            //  if the associated product exists in the cart
            if (shopProductsAssociatedIds.indexOf(assocItem) !== -1) {
                //  find the product in the associated products list
                let idx = shopProductsAssociatedIds.indexOf(assocItem);
                //  remove the product from the list
                shopProductsAssociatedIds.splice(idx, 1);
            }
        }
        
        return shopProductsAssociatedIds;
    }

    @action 
    resetCreditParams = () => {
        this.creditParams = {
            token: '',
            id: '',
            cc_number: '',
            cvv: '',
            month: '',
            year: '',
            track2: ''
        }
    }

    @observable userToken = null;
    @observable userId = null;
    

    @ignore @observable filteredTags = [];
    @ignore @observable filteredShops = [];
    @ignore @observable hasFilters = true;
    @ignore @observable shopsFilterIsOn = false;
    @observable storeOpened = false;
    // @observable isValidSMS= false;
    @observable isWrongSMSCode = false;
    @observable isBlockedUser = false;

    @observable orderData = null;
    @ignore @observable preOrderType = null; //not saved on reloading
    @observable couponDiscount = 0;
    @observable coupons = [];
    @observable hasPostCoupon = false;
    @observable postCouponData = [];
    
    @observable suggestions = [];
    @observable searchValue = '';
    @observable searchFieldIsOpen = false;
    isSearchingProducts = false;
    @observable basketHistory = null; //item-list
    @observable ordersHistory = [];
    @observable historyPage = 1;

    @observable sessionUrl = '';
    @observable creditUniqueId = '';

    @observable subcatItemList = null;

    @observable couponPreopened = false;

    @action
    setCouponPreopened = (value) => {
        this.couponPreopened = value;
    }

    @action
    setSubcatItemList = (itemList) => {
        this.subcatItemList = itemList;
    }

    @action
    setCreditUniqueId = (creditUniqueId) => {
        this.creditUniqueId = creditUniqueId;
    }

    @action
    setHistoryPage = () => {
        this.historyPage = 1;
    }

    multipassCards = [];

    @action
    setMultipassCards(cards) {
        this.multipassCards = cards; // הגדרה של multipassCards
    }

    @action
    setSessionUrl = (url) => {
        this.sessionUrl = url;
    }
    
    @action
    setOrdersHistory = (orders) => {
        this.ordersHistory = [...this.ordersHistory, ...orders];
    }

    @action
    updateOneOrdersHistory = (key, order) => {
        console.log(key);
        console.log(order);
        let oldHistory = this.ordersHistory;
        oldHistory[key] = order;
        this.ordersHistory = oldHistory;
    }

    @action
    resetOrdersHistory = () => {
        this.ordersHistory = [];
    }

    @action
    resetHistoryPage = () => {
        this.historyPage = 1;
    }

    @action
    setBasketHistory = (itemList) => {
        this.basketHistory = itemList;
    }

    @action
    setSuggestions = (itemList) => {
        this.suggestions = itemList;
    }

    @action
    setSearchValue = (text, hide = false) => {
        this.searchValue = text;
        if(hide) this.toggleSearchField(false);
        return true;
    }

    @action
    toggleSearchField = (value) => {
        this.searchFieldIsOpen = value;
    }

    // @observable version = 'v1.0.02342222';

    // @action
    // clearLocalStorage = () => {
    //     console.log("clearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorage")
    //     if(this.version !== 'v1.0.0'){
    //         console.log("clearLocalStorageclearLocalStorageclearLocalStoragec22222222222222222222222222222222learLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorageclearLocalStorage")
    //         this.version = 'v1.0.0'
    //         localStorage.removeItem('__mobx_sync__');
    //     }
    // }
    
    @action
    setDeliveryCost = (deliveryCost) => {
        this.deliveryCost = parseFloat(deliveryCost);
        return this.calcTotalCartPrice();
    }

    setDeliveryType = (deliveryType) => {
        this.deliveryType = deliveryType;
        return toJS(this.setDeliveryCost(deliveryType.price));
    }

    @action
    setCoupon = (code, discount, type) => {
        this.coupons = this.coupons.filter(({id}) => id !== this.shopId);
        if(code) {
            this.coupons.push({code, discount, type, id: this.shopId});
        } else {
            this.clearCoupon();
            // this.closeCartItemForm();
            // this.thisCart.cartItemList = this.thisCart.cartItemList.filter(({isCouponProduct}) => !isCouponProduct);
        }
        this.calcTotalCartPrice();
    }

    //@action
    getCoupon = () => {
        for(let i=0 ; i<this.coupons.length ; i++){
            if((this.shopId === this.coupons[i].id) && this.shopId){
                return this.coupons[i];
            }
        }

        return null;
    }

    @action
    clearCoupon = (inDeletingProduct = false) => {
        this.coupons = this.coupons.filter(({id}) => id !== this.shopId);
        if(!inDeletingProduct) {
            this.thisCart.cartItemList.forEach(({isCouponProduct}, idx) => isCouponProduct && this.removeCartItem(idx))
        }
        return this.calcTotalCartPrice();
    }

    @action
    setWrongSMSCode = flag => {
        if(flag == false) this.isBlockedUser = false;
        this.isWrongSMSCode = flag;
    }

    @action
    setBlockedUser = () => {
        this.isBlockedUser = true;
    }

    @action
    resetCartData = () => {
        this.resetStepNum();
        this.cartPrice = 0;
        this.isEditCartPackForm = false;
        this.isCartItemUpdating = false;
        //this.cartItemUpdating = null;
        //this.selectedCategoryIdx = 0;
        this.shopId && (this.selectedCategoryCode) && (this.selectedCategories[this.shopId] = null);
        this.cartItemUpdatingIdx = null;
        this.isPackItemUpdating = false;
        this.packItemUpdating = null;
    }

    @action
    clearReadyToOrderCartItems = () => {
        for(let i=this.thisCart.cartItemList.length-1 ; i>=0 ; i--){
            //if(this.thisCart.cartItemList[i].isReadyToOrder){
                this.removeCartItem(i);
            //}
        }

    }

    @action
    setCart = () => {
        const isCartInList = this.cartList.some(cart => cart.shopId === this.shopId);
        if(!isCartInList){
            this.cartList.push({cartItemList: [], shopId: this.shopId});
        }
        this.calcTotalCartPrice();
    }

    //@action
    getCart = () => {
        for(let i=0 ; i<this.cartList.length ; i++){
            if(this.cartList[i].shopId === this.shopId){
                return this.cartList[i];
            }
        }
        return null;
    }

    @computed
    get thisCart() { return this.cartList.find(item => item.shopId === this.shopId)??{cartItemList:[]}}

    // @action
    // setValidSMS = (bool) => {
    //     this.isValidSMS = bool;
    // }

    @action
    openStore = async (shopId=null, shopName='', shopCode = '', params={}) => {
        if (this.shopsData && !this.shopsData.shops.length) shopId = 1;
        //if (!shopId) return false;
        
        this.setShopId(shopId);
        this.setShopCode(shopCode);
        
        if (params.preOrderType){
            this.setOrderData('orderType', params.preOrderType);
            this.preOrderType = params.preOrderType;
            //get all real categories for that orderType
            this.getItemList(null, true);
        }
        this.getApiResp_SetShop(params.lng)
        
        //this.setCart();
        
        // this.storeOpened = true;
        return true;

    }

    @action
    closeStore = () => {
        if(!this.shopsData?.shops.length) return;
        console.log('closing store...')
        this.storeOpened = false;
        this.resetCartData();
        /////
        this.resetState();
        this.shopLatLng = null;
        this.deliveryLatLng = null;
        this.deliveryCost = 0;  
        this.csrf = null;
        this.isMaintenance = false;
        //this.apiResp = null;
        //this.shopData = null;
        this.selectedCategoryCode = null;
        this.categories = null;
        //this.shopId = null;

        this.orderData = null;
        //this.resetOrderData();

        //this.shop = null;
        //this.categories = null;
        // this.itemListResp = null;
        // this.itemList = null;

        this.suggestions = [];
        this.searchValue = '';
        this.isSearchingProducts = false;

        this.ordersHistory = [];
        this.basketHistory = null;
        this.historyPage = 1;
        
        /////

    }

    @action
    setFilteredShops = (shops) => {
        console.log('setFilter')
        if(!shops.length && this.filteredTags.length){ //checks if filter not success (Stores Not Found!)
            this.hasFilters = false;
        }
        this.filteredShops = shops;
        this.shopsFilterIsOn = true;
    }

    @action
    addFilteredTag = (tag) => {
        const tagInArr = this.filteredTags.some(t => t.id === tag.id);
        if(tagInArr){
            this.filteredTags = this.filteredTags.filter(t => t.id !== tag.id);
        }
        else{
            this.filteredTags.push(tag)
        }
    }

    @action
    cancelFiltereds = () => {
        this.hasFilters = true;
        this.shopsFilterIsOn = false;
        if(this.filteredTags.length){
            this.filteredTags = [];
        }
        if(this.filteredShops.length){
            this.filteredShops = [];
        }
    }

    // @action
    // setShopResp = async () => {
    //     console.log("this.shopData(1):",this.shopData)
    //     const { shopId } = this.my_env;
    //     let paymentEndpoint = null;
    //     let paymentKey = null;
    //     let directPayment = null;

    //     var form = new FormData();
    //     form.append('shopId', shopId);
    //     const url = this.getApi('set-shop');
    //     // const url = "http://myshop.bigapps.eu.ngrok.io/api/set-shop/"
    //     await axios.post(url, form, null)
    //     .then(response => {
    //         console.log("response:",response) 
    //         console.log("my_env:", my_env) 
    //         paymentEndpoint = response.data.data.shop.paymentEndpoint;
    //         paymentKey = response.data.data.shop.paymentKey;
    //         directPayment = response.data.data.shop.directPayment;
    //     })
    //     .catch(err => {
    //         console.log("err>>>", err)
    //     })

    //     console.log("this.shopData(2):",this.shopData)
    //     const res = { paymentEndpoint, paymentKey, directPayment };
    //     return res;
    // }

    // @action
    // changeOrderType = (toType) => {
    //     const { setOrderData, resetOrderData, shop} = this.props.myShopApiStore;
    //     // this.setState({selectedTimeIdx: ''}); 
    //     this.resetOrderData(); 
    //     this.setOrderData('isDelivery', toType === 'delivery'); 
    //     this.setOrderData('orderType', toType);
    //     if (this.shop.isPriceOrderType)  { 
    //        // this.props.ifaceMngr.setActiveTopMenu('ShopMenu'); 
    //         this.clearReadyToOrderCartItems();
    //     }
    // }

    @action
    setOrderData = (key, value) => {
        if(key === 'orderType' && this.orderData != null && this.orderData.orderType === value) return;

        !this.orderData && this.resetOrderData();
        
        if(this.shop?.isPriceOrderType && key === 'orderType') {
            (this.itemListAll[this.shopId]=null);
            this.closePackItemForm();
            this.closeCartItemForm();
            this.clearReadyToOrderCartItems();
            this.resetOrderData();
        }
        if (key === 'isCredit' && this.shop.isBusiness) value = false;         
        this.orderData[key] = value;
        key==='orderType' && (this.orderData.isDelivery=this.orderData.orderType === 'delivery'); 

        if(!this.itemListAll[this.shopId]) { 
            this.setCategories();
            this.getItemList(0, true,'');
        }
    }
    
    @action
    resetOrderData = () => {
        const orderTypes = this.shopsData?.shops?.find(item=>item.id===this.shopId)?.orderTypes || this.shop?.orderTypes || [];

        const emptyData = {date: '', from: '', to: '', orderComment: ''};
        let orderType = orderTypes?.sort()[0] || 'delivery';

        if(this.shop?.isPriceOrderType && this.orderData?.orderType) orderType = this.orderData.orderType;

        this.orderData = {
            distance: 0,
            isCredit: true,
            isDelivery: orderType === 'delivery', //!orderTypes?.length || orderTypes.includes('delivery'), //hasDeliveryOp,
            orderType, //preChoosenOrderType,
            branch: '',
            deliveryData: { ...emptyData, deliveryGuyComment: '' },
            pickupData: { ...emptyData }
        };
    }

    @action
    getBranchId = () => {
        let branchId = null;
        this.shop.branches &&
            this.shop.branches.forEach(branch => {
                if(branch.name === this.orderData.branch){
                    branchId = branch.id;
                }
            })


        return branchId;
    }

    @action
    getDeliveryCostOfBranch = () => {
        let deliveryCost = 0;
        this.shop.branches &&
            this.shop.branches.forEach(branch => {
                if(branch.name === this.orderData.branch){
                    deliveryCost = branch.deliveryCost;
                }
            })


        return deliveryCost;
    }


    @action
    setShopId = (shopId) => {
        //this.shopId !== shopId && this.storeOpened && this.closeStore();
        this.shopId = shopId; // ?? (this.isSingleStore ? 1: null);
    }

    @action
    setShopName = (shopName) => {
        this.shopName = shopName;
    }

    @action
    setShopCode = (shopCode) => {
        this.shopCode = shopCode;
    }

    @action
    validateCardResp = async (TerminalNumber, Password, Track2, CardNumber, ExpDate_MMYY) => {
        const data = {
            TerminalNumber,
            Password,
            Track2,
            CardNumber,
            ExpDate_MMYY
        }

        const headers = { "Content-Type": 'application/json; charset=utf-8' };

        let token = null;
        let errorMsg = '';
        const url = "https://pci.zcredit.co.il/ZCreditWS/api/Transaction/ValidateCard";
        
        await axios.post(url, data, { headers })
        .then(response => {
            // console.log("validateCardResp RESP::", response)
            token = response.data.Token;
            errorMsg = response.data.ReturnMessage;
        })
        .catch(err => {
            // console.log("validateCardResp ERROR::", err);
            errorMsg = 'ERROR';
        })

        // console.log("validateCardResp ERROR_MSG::",errorMsg)
        return {token, errorMsg};
    }

    @action
    commitFullTransResp = async (token, TerminalNumber, Password, Track2, HolderID, CardNumber, CVV, ExpDate_MMYY) => {
        const deliveryPrice = 0; // NEED UPDATE DELIVERY PRICE !!!
        const totalPrice = this.cartPrice + deliveryPrice;
        let referenceNumber = null;
        let errorMsg = '';

        const data = {
            TerminalNumber,
            Password,
            Track2,
            CardNumber,
            ExpDate_MMYY,
            CVV,
            TransactionSum: totalPrice.toFixed(2), //(format: 00.00)
            CurrencyType: 1, //(use 1 for NIS)
            J: 5,
            HolderID,
            CustomerName: this.userParams.name,
            PhoneNumber: this.userParams.phone,
            CustomerEmail: this.userParams.email,
            ObeligoAction: 0,
        }

        const headers = { "Content-Type": 'application/json; charset=utf-8' };
        const url = "https://pci.zcredit.co.il/ZCreditWS/api/Transaction/CommitFullTransaction";

        const resp = await axios.post(url, data, { headers })
        .then(response => {
            // console.log("CommitFullTransaction RESP::", response);
            referenceNumber = response.data.ReferenceNumber;
            errorMsg = response.data.ReturnMessage;
        })
        .catch(err => {
            // console.log("CommitFullTransaction ERROR::", err)
            errorMsg = 'ERROR';
        })
        
        // console.log("CommitFullTransaction ERROR_MSG::",errorMsg)
        return {referenceNumber, errorMsg};
    }
    
    @action
    getProductsParam = () => {
        let products = [];
        const { cartItemList } = this.thisCart;
        let index=-1;
        for(let idx=0 ; idx<cartItemList.length ; idx++){
            if(cartItemList[idx].isReadyToOrder){
                const { productType } = cartItemList[idx].item.product;
                index++;

                if(productType === 'pack'){  // pack product
                    const packIndex = index;
                    products.push([`products[${index}][isPack]`, 1])
                    products.push([`products[${index}][shopProductId]`, cartItemList[idx].item.id])
                    products.push([`products[${index}][unitType]`, cartItemList[idx].item.defaultUnitType])
                    products.push([`products[${index}][amount]`, cartItemList[idx].quantity])
                    products.push([`products[${index}][comment]`, cartItemList[idx].comment])
                    
                    cartItemList[idx].steps.forEach(step => {
                        step.packItems.forEach(p_item => {
                            if(p_item.isReadyToOrder){
                                index++;
                                const hasProductOption = (p_item && p_item.options) ? Object.keys(p_item.options.productOption).length > 0 : false;
                                const hasToppings = (p_item && p_item.options) ? p_item.options.toppings.length > 0 : false;
                                const hasBaseToppings = (p_item && p_item.item && p_item.item.shopBaseToppings) ? p_item.item.shopBaseToppings.length > 0 : false;
                                
                                products.push([`products[${index}][relatedToPack]`, packIndex])
                                products.push([`products[${index}][shopProductId]`, p_item.item.id])
                                products.push([`products[${index}][unitType]`, p_item.item.defaultUnitType])
                                products.push([`products[${index}][amount]`, p_item.quantity])
                                products.push([`products[${index}][shopProductOptionIsPaid]`, step.optionsPaid])
                                products.push([`products[${index}][levelId]`, step.id])
                                
                                if(hasProductOption){
                                    products.push([`products[${index}][shopProductOptionId]`, p_item.options.productOption.id]);
                                }
                                
                                if(hasToppings){
                                    let quartersAmount = 0;
                                    let isPartialpaymentpaid = false // flag  - after partial payment topping! 
                                    const quartersFree = p_item.toppingsFree * 4; //max quarters free
                                    p_item.options.toppings.forEach((topping, t_idx) => {
                                        let toppingQuartersToPaid = 0; // for partial topping quarters payment
                                        let toppingIsPaid = null;
                                        let toppingQuartersAmount = topping.quarterList.filter(q => q).length;
                                        const pizzaToppingPriceDivide = p_item.item.product.pizzaToppingPriceDivide ?? 1
                                        toppingQuartersAmount = pizzaToppingPriceDivide == 0 ? 4 : toppingQuartersAmount;
                                        quartersAmount += toppingQuartersAmount;
                                        
                                        if(quartersAmount <= quartersFree){ //all topping quarters are free
                                            toppingIsPaid = 0
                                        }
                                        else{
                                            toppingQuartersToPaid = quartersAmount - quartersFree;
                                            const notpart = quartersAmount - toppingQuartersAmount === quartersFree;
                                            if(toppingQuartersToPaid > 0 && !isPartialpaymentpaid && !notpart){ //partial of topping quarters are free
                                                toppingIsPaid = 'part'
                                            }
                                            else{ //all of topping quarters are paid
                                                isPartialpaymentpaid = true;
                                                toppingIsPaid = 1;
                                            }
                                        }

                                        const q1 = topping.quarterList[0] ? '1' : '0';
                                        const q2 = topping.quarterList[2] ? '1' : '0';
                                        const q3 = topping.quarterList[1] ? '1' : '0';
                                        const q4 = topping.quarterList[3] ? '1' : '0';
                                        const positions = q1 + q2 + q3 + q4;

                                        if(toppingIsPaid !== 'part'){ //also for regular pack-item (with toppings)
                                            products.push([`products[${index}][shopToppingIds][${t_idx}]`, topping.id])

                                            if(p_item.item.product.productType === 'pizza'){
                                                products.push([`products[${index}][shopToppingPositions][${t_idx}]`, positions])
                                                products.push([`products[${index}][shopToppingIsPaid][${t_idx}]`, toppingIsPaid ])
                                            }
                                            else{
                                                //const isToppingPaid = t_idx < p_item.toppingsFree ? 0 : 1;
                                                // if there is paidAmount and not all toppings paid there should be same toping twice.
                                                let additionIndex=0;
                                                topping.paidAmount!==topping.shopToppingAmount //if not all toppings paid
                                                    && products.push([`products[${index}][shopToppingIsPaid][${t_idx}]`, 0])
                                                    && products.push([`products[${index}][shopToppingAmount][${t_idx}]`, topping.shopToppingAmount - topping.paidAmount])
                                                    && (additionIndex=0.5);
                                                topping.paidAmount>0 
                                                    && products.push([`products[${index}][shopToppingIds][${t_idx+additionIndex}]`, topping.id])
                                                    && products.push([`products[${index}][shopToppingIsPaid][${t_idx+additionIndex}]`, 1])
                                                    && products.push([`products[${index}][shopToppingAmount][${t_idx+additionIndex}]`, topping.paidAmount]);
                                                
                                            }
                                        }
                                        else{
                                            isPartialpaymentpaid = true;
                                            let positionsPaid = '';
                                            let positionsFree = '';
                                            let count = 0;
                                            for(let i=0; i<positions.length ; i++){
                                                if(positions[i] === '1'){
                                                    count++;
                                                    if(toppingQuartersToPaid >= count){
                                                        positionsPaid += '1';
                                                        positionsFree += '0'
                                                    }
                                                    else{
                                                        positionsPaid += '0';
                                                        positionsFree += '1'
                                                    }
                                                }
                                                else{
                                                    positionsPaid += '0';
                                                    positionsFree += '0'
                                                }
                                            }
                                            products.push([`products[${index}][shopToppingIds][${t_idx}]`, topping.id])
                                            products.push([`products[${index}][shopToppingPositions][${t_idx}]`, positionsPaid])
                                            products.push([`products[${index}][shopToppingIsPaid][${t_idx}]`, 1])

                                            products.push([`products[${index}][shopToppingIds][${t_idx+0.5}]`, topping.id])
                                            products.push([`products[${index}][shopToppingPositions][${t_idx+0.5}]`, positionsFree])
                                            products.push([`products[${index}][shopToppingIsPaid][${t_idx+0.5}]`, 0])
                                            
                                        }

                                    })
                                }

                                if(hasBaseToppings){
                                    //find unSelected base toppings
                                    const unSelectedBaseToppings = p_item.item.shopBaseToppings?.filter(shopBaseTopping => 
                                        p_item.options.baseToppings?.every(nsBaseTopping => nsBaseTopping.id != shopBaseTopping.id))


                                    unSelectedBaseToppings.forEach((baseTopping, bt_idx) => {
                                        products.push([`products[${index}][shopBaseToppingIds][${bt_idx}]`, baseTopping.id])
                                    })
                                }
                            }
                        })
                    })
                } else { // regular/pizza product
                    const hasProductOption = (cartItemList[idx] && cartItemList[idx].options) ? Object.keys(cartItemList[idx].options.productOption).length > 0 : false;
                    const hasToppings =  (cartItemList[idx] && cartItemList[idx].options) ? cartItemList[idx].options.toppings.length > 0 : false;
                    const hasBaseToppings =  (cartItemList[idx] && cartItemList[idx].item && cartItemList[idx].item.shopBaseToppings) ? cartItemList[idx].item.shopBaseToppings.length > 0 : false;
                    
                    products.push([`products[${index}][shopProductId]`, cartItemList[idx].item.id])
                    products.push([`products[${index}][unitType]`, cartItemList[idx].unitType ? cartItemList[idx].unitType.type : cartItemList[idx].item.defaultUnitType])
                    products.push([`products[${index}][amount]`, cartItemList[idx].quantity])
                    products.push([`products[${index}][comment]`, cartItemList[idx].comment])

                    if(hasProductOption){
                        products.push([`products[${index}][shopProductOptionId]`, cartItemList[idx].options.productOption.id]);
                    }
                    
                    if(hasToppings){
                        cartItemList[idx].options.toppings.forEach((topping, t_idx) => {
                            const q1 = topping.quarterList[0] ? '1' : '0';
                            const q2 = topping.quarterList[2] ? '1' : '0';
                            const q3 = topping.quarterList[1] ? '1' : '0';
                            const q4 = topping.quarterList[3] ? '1' : '0';
                            const positions = q1 + q2 + q3 + q4;

                            products.push([`products[${index}][shopToppingIds][${t_idx}]`, topping.id]);
                            if(productType === 'pizza'){
                                products.push([`products[${index}][shopToppingPositions][${t_idx}]`, positions]);
                            } else {
                                products.push([`products[${index}][shopToppingAmount][${t_idx}]`, topping.shopToppingAmount]);
                            }

                        })
                    }

                    if(hasBaseToppings){
                        //find unSelected base toppings
                        const unSelectedBaseToppings = cartItemList[idx].item.shopBaseToppings?.filter(shopBaseTopping => 
                            cartItemList[idx].options.baseToppings?.every(nsBaseTopping => nsBaseTopping.id != shopBaseTopping.id))

                        unSelectedBaseToppings.forEach((baseTopping, bt_idx) => {
                            products.push([`products[${index}][shopBaseToppingIds][${bt_idx}]`, baseTopping.id])
                        })
                    }
                }
            }
        }

        return products;
    }

    getTimeStamp = () => {
        return Math.floor(Date.now() / 1000);
    }
    
    getFormatedTimeHHMM = () => { //return formated string->   HH:MM 
        const date = new Date();

        const hh = date.getHours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
        
        const mm = date.getMinutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
        
        return `${hh}:${mm}`;
    }

    @action
    checkCartItemPresence = async () => {
        const updatedItems = await this.reGetCartProducts();

        const old_length = this.thisCart.cartItemList.length;

        const cartCategories = updatedItems.map(i=>i.product.category);
        const missedCategories = this.thisCart.cartItemList.map(i=>i.item.product.category).filter(cat=>!cartCategories.includes(cat));
        this.categories = this.categories.filter(item => !missedCategories.includes(item.codename));

        this.thisCart.cartItemList = this.thisCart.cartItemList.filter(cartItem=>
            updatedItems.find(product=>product.id==cartItem.item.id) !== undefined);

        const new_length = this.thisCart.cartItemList.length;

        this.calcTotalCartPrice();
        missedCategories.includes(this.selectedCategoryCode) && this.getItemList(0, true);// && (this.selectedCategoryCode = null); 

        return new_length==old_length;
    }

    //@action
    reGetCartProducts = async () => {
        this.isProductsLoading = true;
        const url = this.getApi('get-products');
        const form = new FormData();
        form.append('shopId', this.shopId);
        form.append('orderType', this.orderData?.orderType??'delivery');
        form.append('categoryCodename', '');
        form.append('source', 'site');
        this.thisCart.cartItemList.forEach((cartItem, p_idx) => {
            form.append(`cartProductIds[${p_idx}]`, cartItem.item.id);
        });
        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });
        this.isProductsLoading = false;            
        return resp?.data?.data?.cartProducts;
    }

    @action
    checkMultipassResp = async() => {
        const products = this.getProductsParam();
        const form = new FormData();
        
        const existingCardIndex = this.multipassCards.findIndex(card => 
            card.multipassCardNumber === this.cardNumber
        );

        if (existingCardIndex !== -1) {
            this.multipassCards.splice(existingCardIndex, 1);
        }

        const multipassAmount = this.multipassCards.reduce((total, card) => total + card.amount + card.benefits + card.discounts, 0);
        form.append('lefttopay', parseFloat(this.cartPrice-multipassAmount).toFixed(2));
        form.append('totalPayCounted',this.cartPrice);
        products.forEach(product => {
            form.append(product[0], product[1]);
        })

        if(this.multipassCards.length > 0) {
            const cardsDetailsString = this.multipassCards.map(item => {
                const benefitsProductsString = item.benefitsProducts
                    .map(product => `${product.Id},${product.leftToPayProduct}`)
                    .join(';') || '0';
                return `${item.amount}|${item.benefits}|${item.discounts}|${item.multipassCardNumber}|0|${benefitsProductsString??0}`;
            }).join('||');

            form.append(`multipassCardsStack`, cardsDetailsString);
        }

        form.append('source', "site");
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('orderType', this.orderData?.orderType??'delivery');;
        form.append('paymentType', (!this.shop.withoutPrices && this.shop.isBusiness) ? 'business' : (this.otherPaymentType ? this.otherPaymentType : (this.orderData.isCredit ? 'credit' : 'cash')));
        form.append('paymentCard', this.cardNumber);
        form.append('deliveryCost', this.orderData.isDelivery ? this.deliveryCost : null);

        this.setBlockClosing();
        const url = this.getApi('check-multipass');
        const res = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Bearer': this.userToken
            },
        });
        
        if(res.data.errorCode != 0) {
            // console.log("makeOrder ERROR::", res.data);
            //this.clearReadyToOrderCartItems();
            this.clearBlockClosing();
        } else {
            // console.log("makeOrder RESP::", res);
            if(res?.data?.promotionCoupon?.code){
                this.hasPostCoupon = true;
                this.postCouponData = res?.data?.promotionCoupon;
            }
            
        }
      
        return res;
    }

    @action
    makeOrderResp = async (referenceNumber, token, creditData) => {
        this.hasPostCoupon = false;
        //const { directPayment } = this.shop;
        const products = this.getProductsParam();
        const form = new FormData();
        const coupon = this.getCoupon();

        let thisPaymentType = (!this.shop.withoutPrices && this.shop.isBusiness) ? 'business' : (this.otherPaymentType ? this.otherPaymentType : (this.orderData.isCredit ? 'credit' : 'cash'));

        if(this.otherPaymentType != 'multipass' && this.multipassCards.length > 0) {

            const cardsDetailsString = this.multipassCards.map(item => {
                const benefitsProductsString = item.benefitsProducts
                    .map(product => `${product.Id},${product.leftToPayProduct}`)
                    .join(';') || '0';
                return `${item.amount}|${item.benefits}|${item.discounts}|${item.multipassCardNumber}|0|${benefitsProductsString??0}`;
            }).join('||');
            const multipassAmount = this.multipassCards.reduce((total, card) => total + card.amount + card.benefits + card.discounts, 0);

            let paymentTypesSplit = [];
            paymentTypesSplit.push([`paymentTypesSplit[0][codename]`, thisPaymentType])
            paymentTypesSplit.push([`paymentTypesSplit[0][sum]`, this.cartPrice-multipassAmount])
            paymentTypesSplit.push([`paymentTypesSplit[1][codename]`, 'multipass'])
            paymentTypesSplit.push([`paymentTypesSplit[1][sum]`, multipassAmount])
            paymentTypesSplit.push([`paymentTypesSplit[1][cardParams]`, cardsDetailsString])
            
            this.setOtherPaymentType('split');

            paymentTypesSplit.forEach(paymentTypesSplit_row => {
                form.append(paymentTypesSplit_row[0], paymentTypesSplit_row[1]);
            })
        } else if(this.otherPaymentType == 'multipass') {
            const multipassAmount = this.multipassCards.reduce((total, card) => total + card.amount + card.benefits + card.discounts, 0);
            form.append('lefttopay', parseFloat(this.cartPrice-multipassAmount).toFixed(2));
        }
        products.forEach(product => {
            form.append(product[0], product[1]);
        })

        form.append('source', "site");
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('onesignalId', ''); //CHECK THIS! 
        form.append('orderType', this.orderData?.orderType??'delivery');;
        form.append('paymentType', (!this.shop.withoutPrices && this.shop.isBusiness) ? 'business' : (this.otherPaymentType ? this.otherPaymentType : (this.orderData.isCredit ? 'credit' : 'cash')));
        this.cardNumber && this.otherPaymentType && form.append('paymentCard', this.cardNumber);
        form.append('coupon', coupon ? coupon.code : ''); //CHECK THIS!
        this.orderData.branch && form.append("branchId", this.getBranchId());

        // if(this.orderData.isCredit){
        //     form.append('creditTxHolderId', creditData.id);
        //     form.append('creditTxExpDate', creditData.expDate);
        //     form.append('creditTxConfirm', token);
        //     form.append('creditTxCardNo', creditData.cc_number.slice(-4));
        //     if(directPayment === 1){
        //         form.append('creditTxCVV', creditData.cvv);
        //     }
        //     else{
        //         form.append('creditTxIndex', referenceNumber);
        //     }
        // }

        if(this.orderData.isCredit || this.creditUniqueId){
            // console.log("uniqueId:", this.creditUniqueId)
            form.append('UniqueID', this.creditUniqueId);
        }

        if(this.orderData.isDelivery){
            const { deliveryData } = this.orderData;
            form.append('deliveryComment', deliveryData.deliveryGuyComment);
            form.append('deliveryDate', this.shop.withoutFuture_delivery ? this.getTimeStamp() : deliveryData.date);
            form.append('deliveryFrom', this.shop.withoutFuture_delivery ? this.getFormatedTimeHHMM() : deliveryData.from);
            form.append('deliveryTo', this.shop.withoutFuture_delivery ? this.getFormatedTimeHHMM() : deliveryData.to);
            form.append('comment', deliveryData.orderComment);
            form.append('deliveryCost', this.deliveryCost);
            form.append('distance', this.orderData.distance);
            form.append('deliveryLat', this.deliveryLatLng ? this.deliveryLatLng.lat : null);
            form.append('deliveryLon', this.deliveryLatLng ? this.deliveryLatLng.lng : null);
            if (this.deliveryType) {
                form.append('deliveryType', this.deliveryType.codename);
                form.append('deliveryId', this.deliveryType.deliveryId);
            }
        }
        else{ /// pickup
            const { pickupData } = this.orderData;
            form.append('deliveryDate', this.shop.withoutFuture_pickup ? this.getTimeStamp() : pickupData.date);
            form.append('deliveryFrom', this.shop.withoutFuture_pickup ? this.getFormatedTimeHHMM() : pickupData.from);
            form.append('deliveryTo', this.shop.withoutFuture_pickup ? this.getFormatedTimeHHMM() : pickupData.to);
            form.append('deliveryCost', null);
            form.append('comment', pickupData.orderComment);
            form.append('distance', 0);
            pickupData.pickupDelayTime && form.append('delay', parseInt(pickupData.pickupDelayTime));
        }

        if(this.orderData.doAnotherOrder) {
            form.append('newOrder', 1);
        }

        this.setBlockClosing();
        const url = this.getApi('make-order');
        const res = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Bearer': this.userToken
            },
        });
        
        if(res.data.errorCode != 0) {
            // console.log("makeOrder ERROR::", res.data);
            //this.clearReadyToOrderCartItems();
            this.clearBlockClosing();
        } else {
            // console.log("makeOrder RESP::", res);
            if(res?.data?.promotionCoupon?.code){
                this.hasPostCoupon = true;
                this.postCouponData = res?.data?.promotionCoupon;
            }
            
        }
      
        return res;
    }

    @action
    setStepNum = (stepNum) => {
        this.stepNum = stepNum;
        if(stepNum > this.maxStepNum) this.setMaxStepNum(stepNum);
    }

    @action
    setMaxStepNum = (maxStepNum) => {
        this.maxStepNum = maxStepNum;
    }

    @action
    resetStepNum = () => {
        this.setStepNum(1);
        this.setMaxStepNum(1);
    }
    promoCalc = {
        x_units_y_money: (price, qty, promo) => qty >= promo.x ? Math.floor(qty / promo.x) * promo.y + (qty % promo.x) * price : null,
        x_units_y_units: (price, qty, promo) => qty >= promo.x ? Math.floor(qty / promo.x) * promo.y * price  + (qty % promo.x) * price : null,
        percent: (price, qty, promo) => price * qty / 100 * (100-promo.percent),
    }
    
    getItemPrice = (item, isSubItem = false, PackItemPaid = false, withPromotions = false) => {
        let price = 0;
        const { product, shopProductOptions, shopToppings } = item.item;

        if(!isSubItem){
            price = item.unitType ? item.unitType.price : item.item.unitTypes[0].price;
            const promotion = withPromotions && item.item.promotions?.length > 0 ? item.item.promotions[0] : null;
            if (promotion) {
                const promoQuantity = !promotion.maxAmount ? item.quantity : (promotion.maxAmount >= item.quantity ? item.quantity : promotion.maxAmount);
                const promoPrice = this.promoCalc[promotion.promotionType](price, promoQuantity, promotion);
                price = promoPrice ? (promoPrice + (item.quantity - promoQuantity) * price) : price * item.quantity;
            } else {
                price *= item.quantity;
            }
        }

        PackItemPaid && (price += (item.item.unitType ? item.item.price : item.item.unitTypes[0].price)) && item.item.quantity>1 && (price *= item.item.quantity); 
        
        const quantityMultiplier = item.item.defaultUnitType == item.unitType.type ? item.quantity : (item.quantity * item.item.product.weight / (this.shopData.country.weight == "kg" ? 1000 : 1));
        //console.log(quantityMultiplier, item.item)

        if(product.productType !=='pack'){ //Checks if the cart-item is editable and its pizza.
            if (shopProductOptions?.length 
                && (product.productType !== "pizza" || !isSubItem ||  item.optionsPaid === 1)) {
                price += item.options.productOption.price * quantityMultiplier; //add price of product option
            }
            if(shopToppings?.length && item.options?.toppings?.length){
                    const quartersFree = item.toppingsFree ? item.toppingsFree * 4 : 0;
                    let quartersCount = 0; // number of toppings quarters
                    const pizzaToppingsConst = (topping) => {
                        if (product?.pizzaToppingPriceDivide === 0) {
                            price = 0;
                            if (topping.quarterList.some(quarter => quarter)) {
                                price += (quartersCount < quartersFree)
                                            ?
                                                0
                                            :
                                            topping.price;
                                quartersCount += 4;
                            }
                            return price;
                        } else {
                            return topping.quarterList.filter(q => q).reduce((sumQ, q) => 
                                (++quartersCount > quartersFree) ? sumQ + topping.price / 4 : sumQ
                            , 0)
                        }
                    }
                
                    price = item.options.toppings.reduce((acc, topping) => 
                        acc + (product.productType === "pizza" ? (pizzaToppingsConst(topping) * quantityMultiplier) : (topping.sum * quantityMultiplier)), 
                        price);
            }
        }

        return price;
    }
    //@action
    getCartItemPrice = (item, withPromotions=false) => 
        this.getItemPrice(item, ...Array(2), withPromotions) + (item.steps?.reduce((price, step) => 
            price + step.packItems
                .filter(packItem => packItem.isReadyToOrder)
                .reduce((pItemPrice, pItem) =>  
                    pItemPrice += this.getItemPrice(pItem, true, step.productsPaid===1)
                , 0)
        , 0) || 0)
    

    @action
    calcTotalCartPrice = () => {
        //checks if cart is empty
        if(!this.thisCart.cartItemList.length){
            this.deliveryCost = 0;
            this.cartPrice = 0;
            this.cartPriceBeforeDiscount = null;
            this.cartCouponDiscount = null;
            return;
        }
        
        let totalPrice = 0;
        let totalPriceBeforeDiscount = 0;
        let couponDiscount = 0;
        
        this.thisCart.cartItemList.filter(cartItem => cartItem.isReadyToOrder)
            .forEach(cartItem => {
                const isPromotionsAllowed = cartItem.item.product.productType !== "pack";
                const itemPrice = this.getCartItemPrice(cartItem, isPromotionsAllowed);
                totalPrice += itemPrice;
                totalPriceBeforeDiscount += isPromotionsAllowed ? this.getCartItemPrice(cartItem) : itemPrice;
            })

        totalPrice = totalPrice.toFixed(2);
        //calculate coupon discount
        const coupon = this.getCoupon();
        switch(coupon?.type){
            case 'percent':
                if (Number.isInteger(coupon.discount)) {
                    couponDiscount = totalPrice * (coupon.discount / 100);
                    totalPrice -= couponDiscount;
                }
                break;
            case 'money':
                totalPrice -= coupon.discount;
                if(totalPrice < 0) totalPrice = 0;
                if(totalPriceBeforeDiscount < 0) totalPriceBeforeDiscount = 0;
                couponDiscount = coupon.discount;
                break;
            case 'product':
                //const item = this.thisCart.cartItemList.find(({isCouponProduct})=>isCouponProduct);
                //totalPrice -= item ? this.getCartItemPrice(item, item.item.product.productType !== "pack") : 0;
                break;
            default:
                break;
        }

        //calculate delivery cost. it made after coupon discount due to the fact that delivery cost is not included in the coupon discount
        totalPrice = parseFloat(totalPrice) + parseFloat(this.deliveryCost);
        totalPriceBeforeDiscount += this.deliveryCost;
        
        this.cartPrice = totalPrice.toFixed(2);
        this.cartPriceBeforeDiscount = totalPriceBeforeDiscount.toFixed(2);
        this.cartCouponDiscount = couponDiscount.toFixed(2);

        return totalPrice;
    }

    @action
    editCartItemUpdating = (selectedProductOption, selectedToppings, selectedBaseToppings) => { 
        //update the "options" of 'cartItemUpdating' variable
        if(this.isPackItemUpdating){ //update cart item of 'pack' type
            //console.log(toJS(this.packItemUpdating));
            if(!this.packItemUpdating.options) this.packItemUpdating.options = {};
            this.packItemUpdating.options.productOption = selectedProductOption;
            this.packItemUpdating.options.toppings = selectedToppings;
            this.packItemUpdating.options.baseToppings = selectedBaseToppings;
        }
        else{
            //console.log(toJS(this.cartItemUpdating));
            if(!this.cartItemUpdating.options) this.packItemUpdating.options = {};
            this.cartItemUpdating.options.productOption = selectedProductOption;
            this.cartItemUpdating.options.toppings = selectedToppings;
            this.cartItemUpdating.options.baseToppings = selectedBaseToppings;
        }
    }
    
    @action
    closeEditCartPackForm = () => {
        this.isEditCartPackForm = false;
    }

    @action
    openPackItemForm = (packItem) => { //opening form for item in pack
        this.isPackItemUpdating = true;
        this.packItemUpdating = packItem;
    }

    @action
    closePackItemForm = () => { //closing form for item in pack
        this.isPackItemUpdating = false;
        this.packItemUpdating = null;
    }

    @action
    openCartItemForm = (cartItemIdx, isCartPackItem = false, isOpenForEdit = false) => {
        this.isOpenForEdit = isOpenForEdit;

        if(isCartPackItem) {
            this.isEditCartPackForm = true 
            this.setStepNum(1);
            this.setMaxStepNum(999);
        };
        this.isCartItemUpdating = true;
        //this.cartItemUpdating = cartItem;
        this.cartItemUpdatingIdx = cartItemIdx;
    }

    @action
    closeCartItemForm = (isLast = true) => {
        this.isCartItemUpdating = false;
        this.cartItemUpdatingIdx = null;
        if(!isLast) {
            if(typeof this.cartItemUpdating?.isCouponProduct != 'undefined' && this.cartItemUpdating.isCouponProduct){
                this.clearCoupon(true);
                //console.log(this.getCoupon())
            }
        }
        this.resetStepNum();
    }

    

    @action
    removeCartItem = (index, removeMult) => {
        let newCartItemList = null;
        let canReduceQuantity = false;
        

        if(this.thisCart.cartItemList){
            this.isCartItemUpdating && this.closeCartItemForm();
            //checks if item amount is unequal to 1
            this.thisCart.cartItemList.forEach((cartItem, idx) => {
                if(index === idx){
                    if(typeof cartItem.isCouponProduct != 'undefined' && cartItem.isCouponProduct){
                        this.clearCoupon(true);
                    }

                    const unitTypeMult = cartItem.unitType ? cartItem.unitType.multiplier : cartItem.item.unitTypes[0].multiplier;
                    if(cartItem.quantity !== unitTypeMult && cartItem.quantity-unitTypeMult > 0){
                        canReduceQuantity = true;
                    }
                }
            });

            if(removeMult){
                newCartItemList = [];
                if(canReduceQuantity){
                    this.thisCart.cartItemList.forEach((cartItem, idx) => {
                        if(index === idx){
                            const unitTypeMult = cartItem.unitType ? cartItem.unitType.multiplier : cartItem.item.unitTypes[0].multiplier;
                            cartItem.quantity = this.fixFloatingPoint(cartItem.quantity-unitTypeMult);
                        }
                        newCartItemList.push(cartItem);
                    });
                }
                else{
                    newCartItemList = this.thisCart.cartItemList.filter((_, idx) => {
                        const isChosenItem = index === idx;
                        return !isChosenItem;
                    });
                }
            }
            else{
                newCartItemList = this.thisCart.cartItemList.filter((_, idx) => {
                    const isChosenItem = index === idx;
                    return !isChosenItem;
                });
            }
        }
        this.thisCart.cartItemList = [...newCartItemList];

        this.calcTotalCartPrice();
    }

    fixFloatingPoint = val => Number.parseFloat(val.toFixed(13))

    @action
    addToCart = (item, unitType, isCouponProduct = false) => {
        let itemInCart = false;
        const hasProductOptions = (item && item.shopProductOptions) ? item.shopProductOptions.length : false;
        const hasToppings = (item && item.shopToppings) ? item.shopToppings.length > 0 : false;
        const hasBaseToppings = (item && item.shopBaseToppings) ? item.shopBaseToppings.length > 0 : false;
        const isEditableItem = hasProductOptions || hasToppings || hasBaseToppings;  
        const isCartPackItem = (item && item.product && item.product.levels) ? item.product.levels.length : null;

        if(this.thisCart.cartItemList.length){ // Checks if there is the same item in the cart
            itemInCart = this.thisCart.cartItemList.some(cartItem => cartItem.item.id === item.id);
        }
        if(!isEditableItem && !isCartPackItem && itemInCart){ //Checks if item is in cart and is not editable and not a pack
            this.thisCart.cartItemList.forEach(cartItem => {
                const mult = cartItem.unitType ? cartItem.unitType.multiplier : cartItem.item.unitTypes[0].multiplier;
                if(cartItem.item.id === item.id){
                    cartItem.quantity = this.fixFloatingPoint(cartItem.quantity + mult);
                }
            })
        }
        else if(isCartPackItem){
            let steps = [];
            let stepNum = 1;
            const { levels } = item.product;

            levels.forEach((level) => {
                const { productsAmount, optionsPaid, toppingsAddPaid, toppingsFree } = level;
                const { products, ...otherLevelProps} = level;
                for(let i=1; i<=productsAmount ; i++){
                    let packItems = [];
                    products.forEach(product => {
                        const isEditablePackItem = product.shopProductOptions.length || (product.shopToppings.length && (toppingsAddPaid == 1 || toppingsFree > 0)) || product.shopBaseToppings?.length;
                        let productOption = {};
                        if(product.shopProductOptions.length){
                            const defaultOption = product.shopProductOptions.filter(opt => opt.price === 0);
                            productOption = {
                                name: defaultOption.length ? defaultOption[0].productOption.name : product.shopProductOptions[0].productOption.name, 
                                price: optionsPaid ? (defaultOption.length ? defaultOption[0].price : product.shopProductOptions[0].price) : 0,
                                id: defaultOption.length ? defaultOption[0].id : product.shopProductOptions[0].id,
                            };
                        }

                        const options = { 
                            productOption,
                            toppings: [],
                            baseToppings: product.shopBaseToppings?.length > 0 ? product.shopBaseToppings : [],
                        };
                        const newPackItem = {
                            item: product, 
                            quantity: 1,
                            id: uuidv4(),
                            isReadyToOrder: false,
                            unitType,
                            comment: ''
                        };
                        if(isEditablePackItem){
                            newPackItem.options = options;
                            newPackItem.optionsPaid = optionsPaid;
                            newPackItem.toppingsAddPaid = toppingsAddPaid;
                            newPackItem.toppingsFree = toppingsFree;
                        }
                        newPackItem.isEditablePackItem = isEditablePackItem;
                        packItems.push(newPackItem)
                    })

                    steps.push({...otherLevelProps, stepNum: stepNum++, itemNum: i, packItems});
                }
            })

            const newCartItem = {
                item, 
                quantity: 1,
                id: uuidv4(),
                isReadyToOrder: true,
                steps,
                unitType,
                comment: '',
                isCouponProduct: isCouponProduct
            };
            this.thisCart.cartItemList.push(newCartItem);
            this.openCartItemForm(this.thisCart.cartItemList.length-1); //open form editor after submited!
        }
        else if(!isEditableItem){ //if item is NOT in cart and is not editable
            const newCartItem = {
                item, 
                quantity: unitType.multiplier,
                id: uuidv4(),
                isReadyToOrder: true,
                unitType,
                comment: ''
            };
            this.thisCart.cartItemList.push(newCartItem);
        }
        else{
            let productOption = {};
            if(hasProductOptions){
                const defaultOption = item.shopProductOptions.filter(opt => opt.price === 0);
                productOption = {
                    name: defaultOption.length ? defaultOption[0].productOption.name : item.shopProductOptions[0].productOption.name, 
                    price: defaultOption.length ? defaultOption[0].price : item.shopProductOptions[0].price,
                    id: defaultOption.length ? defaultOption[0].id : item.shopProductOptions[0].id
                };
            }
            const options = { 
                productOption,
                toppings: [],
                baseToppings: item.shopBaseToppings?.length > 0 ? item.shopBaseToppings : [],
            };

            const newCartItem = (item.defaultUnitType == "unit") ? {
                item, 
                options,
                quantity: 1,
                id: uuidv4(),
                isReadyToOrder: true,
                unitType,
                comment: ''
            } : {
                item, 
                options,
                quantity: unitType.multiplier,
                id: uuidv4(),
                isReadyToOrder: true,
                unitType,
                comment: '',
                isWeight: true
            };

            this.thisCart.cartItemList.push(newCartItem);
            this.openCartItemForm(this.thisCart.cartItemList.length-1); //edit after submited!
        }
        this.calcTotalCartPrice();
    };

    @action
    setMaxSideMenuIdx(idx) {
        if (idx > this.MaxSideMenuIdx) {
            this.MaxSideMenuIdx = idx;
        }
    }

    @action
    resetState = () => {
        this.init = false;
        this.isMaintenance = my_env.mStatus == "true"? true : false;
        this.MaxSideMenuIdx = 0;
        this.ActiveSideMenuIdx = 0;
        this.shopId && (this.selectedCategories[this.shopId] = null);
        //this.itemListAll[this.selectedCategoryCode] = null;
        //this.bgCode = this.bgCodeDef;
        this.isProductsLoading = false;
        this.isShopsLoading = false;
        this.isShopLoading = false;
        this.couponPreopened = false;
    }

    @action
    setInit = async () => { // triggered by mobx-sync Trunk load 
        this.resetState();
        if(this.shopId) this.openStore(this.shopId);
        // this.getApiResp();
        //const resp = await this.getApiResp_SetShop();
        // if(resp) {
        //     this.setData();
        this.init = true;
        
        //     return true;
        // } else {
        //     return false;
        // }

        //console.log({isOpen: this.storeOpened, shopId: this.shopId});
        // if (this.storeOpened && this.shopId) {
        //     this.getApiResp_SetShop();
        //     if( !this.itemListAll[this.shopId]) {
        //         const catIndex = this.selectedCategoryCode?this.categories.findIndex(item=>item.codename === this.selectedCategoryCode):0;
        //         this.getItemList(catIndex===-1?0:catIndex, true);
        //     }
        // }

        
    }

    // @action
    // setData(){

    //     ////////////////
    //     !this.shopsData && this.setCart(); //added for single store ******
    //     ///////////////
    //     //this.setShopData();
    //     //this.setShop();
    //     //await 
    //     this.setShopLatLng()
    //     this.setCategories();

    //     this.getItemList(this.selectedCategoryIdx, true);
    //     this.setBgCode();
    //     this.setBgCodeWay();
    //     this.setBodyBg();
        
    // }

    @computed
    get mainColor() { return this.shop?.mainColor ? '#'+this.shop.mainColor : this.mainColorDef; }

    get blackTexts() { return this.shop?.blackTexts == 1 }

    get hideWorkingTimes() { return this.shop?.hideWorkingTimes == 1 }

    @action
    generateCsrf = () => {
        this.csrf = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    getUrl = (url='') => {
        return my_env.url + (url === '' ? '' : '/' + url);
    }
    getApi = (url='') => {
        return my_env.url + '/api' + (url === '' ? '' : '/' + url);
    }

    isShopData(){
        return this.shopData !== null;
    }
    isShop(){
        return this.shop !== null;
    }

    // @action
    // getShopData(){
    //     return this.shopData? this.shopData : null;
    // }

    @action
    getAbout(){
        // return this.isShopData()? (this.shopData['about'] ? this.shopData['about'] : null) : null;
        return this.shopData?.about || this.shop?.about || null;
    }

    @action
    getTerms(){
        return this.isShopData()? (this.shopData['rules'] ? this.shopData['rules'] : null) : null;
    }

    @action
    getPPolicy(){
        return this.isShopData()? (this.shopData['privacy_policy'] ? this.shopData['privacy_policy'] : null) : null;
    }

    @action
    getRetPolicy(){
        return this.isShopData()? (this.shopData['return_policy'] ? this.shopData['return_policy'] : null) : null;
    }

    @action
    getAccessibility(){
        return this.isShopData()? (this.shopData['accessibilityMessage'] ? this.shopData['accessibilityMessage'] : null) : null;
    }

    @action
    getContacts(){
        return 'contacts';
    }
    getShopName() {
        return this.isShop()? (this.shop['name'] ? this.shop['name'] : null) : null;
    }
    getShopAddress() {
        return this.isShop()? (this.shop['address'] ? this.shop['address'] : null) : null;
    }
    getShopContactEmail() {
        return 'sales@bigapps.co.il';
    }
    getShopPhone() {
        return this.isShop()? (this.shop['phone'] ? this.shop['phone'] : null) : null;
    }
    getShopPhone2() {
        return this.isShop()? (this.shop['phone2'] ? this.shop['phone2'] : null) : null;
    }
    getShopWorkTimes() {
        return this.isShop()? (this.shop['workingTimes'] ? this.shop['workingTimes'] : null) : null;
    }
    getIsMultipassPosId() {
        return this.isShop()? (this.shop['multipassPosId'] ? this.shop['multipassPosId'] : 1) : 2;
    }
    
    @action
    getHistory(){
        return this.history;
    }
    @action
    clearHistory(){
        this.history = null;
    }

    // @action
    // setBgCode() {
    //     if(this.isShop()) {
    //         this.bgCode = this.shop.backgroundCodename ? this.shop.backgroundCodename : this.bgCodeDef;
    //     }
    // }

    // @action
    // setBgCodeWay() {
    //     if(this.shop !== null && typeof this.shop.backgroundAdminImage !== 'undefined' && this.shop.backgroundAdminImage !== '') {
    //         this.bgCodeWay = this.shop.backgroundAdminImage;
    //     }
    // }

    // @action
    // getBgCode() {
    //     this.setBgCode();
    //     return this.bgCode;
    // }

    // @action
    // getBgCodeWay() {
    //     this.setBgCodeWay();
    //     return this.bgCodeWay;
    // }

    // @action
    // setBodyBg(id='') {
    //     const bgCw = this.getBgCodeWay();
    //     if(bgCw !== '') {
    //         const fileBg = 'url(' + bgCw + ")"; 
    //         this.fileBg = fileBg;
    //         return fileBg;
    //     } else {
    //         const bg = id === '' ? this.getBgCode() : id;
    //         const fileBg = 'url(' + my_env.url + "/upload/bg_admin/" + bg + ".jpg)"; 
    //         this.fileBg = fileBg;
    //         return fileBg;
    //     }
    // }

    // getShopDataItem(item){
    //     return this.shopData[item] ? this.shopData[item] : null;
    // }

    @action
    setShopData = (data) => {
        if(data && !data.country) data.country = {
            default: true,
            codename: "israel",
            currencyCode: "NIS",
            currencyName: "₪",
            dateFormat: "d/m/Y",
            defaultLang: "he",
            defaultLanguage: "hebrew",
            distance: "km",
            name: "Israel",
            phonePrefixes: ["+972"],
            timeFormat: "H:i:s",
            weight: "kg",
        }
        if(!data.country.alpha2Code) {
            const iso3311a2 = require('iso-3166-1-alpha-2');
            data.country.alpha2Code = iso3311a2.getCode(data.country.name ?? 'United States') ?? 'US';
        };

        this.shopData = data;
        //this.openStore(data.shop.id, data.shop.name);
    }

    @action
    changeDataLng = (lng) => {


        if(this.shopData) {
            Object.keys(this.shopData).forEach(key => { 
                if(this.shopData[`${key}_obj`] && this.shopData[`${key}_obj`].i18n?.text) {
                    this.shopData[key] = this.shopData[`${key}_obj`].i18n.text[lng];
                }
            })
            if(this.shopData.shop && this.shopData.shop.i18n) {
                Object.keys(this.shopData.shop.i18n).forEach(key => 
                    this.shopData.shop.i18n[key][lng] && (this.shopData.shop[key] = this.shopData.shop.i18n[key][lng])
                );
            }
        }
        Object.keys(this.shopsData ?? {}).forEach(key => {
            if(this.shopsData[`${key}_obj`] && this.shopsData[`${key}_obj`].i18n) {
                const obj = this.shopsData[`${key}_obj`].i18n;
                const prop = obj.text || obj.value || obj.name;
                prop && (this.shopsData[key] = prop[lng]);
            }
        })
    }


    // @action
    // setShop(){
    //     if(this.shopData !== null){
    //         this.shop = this.shopData.shop;
    //         // console.log("setShop success.");
    //     }
    // }

    @action
    async setShopLatLng(){
        if(this.shopId && this.shop !== null){
            if(this.shop.shopLat && this.shop.shopLon){
                this.shopLatLng = { shopLat: this.shop.shopLat, shopLng: this.shop.shopLon };
            }
            else if(this.shop.address){
                await Geocode.fromAddress(this.shop.address).then(
                    (response) => {
                      const { lat, lng } = response.results[0].geometry.location;
                      this.shopLatLng = { shopLat: lat, shopLng: lng };
                    },
                    (error) => {
                      console.error('myShopApi > setShopLatLng :: error:', error);
                    }
                  );
            }
        }
    }

    @action
    getShopLatLng = () => {
        return this.shopLatLng;
    }

    @action
    getDeliveryLatLng = () => {
        return this.deliveryLatLng;
    }

    @action
    setDeliveryLatLng = (coords) => {
        this.deliveryLatLng = coords;
    }

    @action
    setCategories = (emptyCategories=[]) => { //emptyCategories can include id's (array of int) or codenames (array of strings)
        if(!this.shopId) return;
        // this.categories = this.shop.categories.sort((a, b) => a.codename > b.codename? 1: -1);
        this.categories = this.shop?.categories.filter(
            category => (
                typeof category.isActive === 'undefined' 
                || category.isActive == '1' 
                || !emptyCategories.includes(category.id)
                || !emptyCategories.includes(category.codename))
        );
        
        this.categories?.length && !this.selectedCategoryCode && (this.selectedCategoryCode = this.categories[0].codename);
        if (!this.categories || !this.categories[this.selectedCategoryIdx]) this.selectedCategories[this.shopId] = null;
        this.setSelectedCategoryCode(this.selectedCategoryCode); //for case if selectedCategoryCode is not in categories
    }
    // @action
    // getCategories(){
    //     return this.categories;
    // }

    getCatCodeByActiveIdx(_idx){
        const code = this.categories && this.categories[_idx] ? this.categories[_idx].codename : null;
        // console.log("getCatCodeByActiveIdx idx:", [_idx, " code:", code]);
        return code;
    }

    @computed
    get selectedCategoryIdx() {
        return !this.selectedCategoryCode?0:this.categories.findIndex(item => item.codename === this.selectedCategoryCode);
    }

    getSearchProducts = async (productName, parentId) => {
        this.isSearchingProducts = true;
        const url = this.getApi('search-products');
        const form = new FormData();
        form.append('shopId', this.shopId);
        form.append('productName', productName);
        parentId && form.append('categoryId', parentId);

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });

        // console.log('resp:', resp)
        this.isSearchingProducts = false;
        return resp.data.errorCode === '0' ? resp.data.data.products : null;
    }

    @action
    initItemList = () => {
        //this.itemListAll[this.shopId][this.selectedCategoryCode] = [];
    }

    @computed
    get selCategory(){
        if(!this.categories || this.categories.length < this.selectedCategoryIdx + 1  ) return {};
        return this.categories[this.selectedCategoryIdx];
    }

    @computed 
    get hasCategoryTimeLimit() {
        if(!this.selCategory) return false; 
        //if(!this.categories || this.categories.length < 1 || this.selectedCategoryIdx < 0 || !this.categories[this.selectedCategoryIdx]) return false;
        const { fromTime, toTime } = this.selCategory; //this.categories[this.selectedCategoryIdx]; //:{fromTime: null, toTime: null};
        return this.selCategory && fromTime && toTime && fromTime!==toTime;
    }

    @action 
    reGetCategories() { //There is no special action for get Categories so we should use get products
        const form = new FormData();
        form.append('shopId', this.shopId);
        form.append('orderType', this.orderData?.orderType??'delivery');
        form.append('pageSize', 1);
        form.append('page',1);

        axios({
            url: my_env.url + '/api/get-products',
            method: 'post',
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            }
        }).then(resp => {
            if(!resp.data.errorCode?.length) {
                this.categories = this.categories.filter(item => !Object.keys(resp.data.categoryProducts).contains(item.codename));
            } 
        })
    }

    @action
    getItemList = (_idx, is_new, sub_cat_codename = null) => {
        // if(is_new) {
        //     this.itemList = [];
        // }

        if (this.isProductsLoading) return;
        this.isProductsLoading = true;
        const form = new FormData();
        const catTarget = _idx !== null && this.categories && this.categories[_idx] ? this.categories[_idx].codename : null;
        const catBackUp =  this.selectedCategoryCode;
        this.selectedCategoryCode = catTarget ?? (this.categories?.length?this.categories[0].codename:null);
        catTarget && /*this.itemListAll && this.itemListAll[this.shopId] &&*/ form.append('categoryCodename', catTarget);
        sub_cat_codename && form.append('subCategoryCodename', sub_cat_codename);
        const url = this.getApi('get-products');
        
        // form.append('shopId', my_env.shopId);
        form.append('shopId', this.shopId??1);
        form.append('orderType', this.orderData?.orderType??'delivery');
        
        
        form.append('pageSize', 10);
        this.categoryPage = is_new ? 1 : this.categoryPage + 1;
        form.append('page', this.categoryPage);

        axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        }).then((resp) => {
            if(resp.data.error != null) {
                this.isMaintenance = true;
                //this.categoryPage = categoryPage;
                this.categoryPageCount = 0;
                this.setSelectedCategoryCode(catBackUp);
            } else {
                this.isMaintenance = false;
                if (!this.storeOpened) this.storeOpened = true;
                if( resp.data.data.categoryProducts || Object.keys(resp.data.data.categoryProducts).length>0) {
                    // REMOVE CATEGORIES WITH ZERO PRODUCTS
                    this.categories = this.categories?.filter(item => 
                        resp.data.data.categoryProducts[item.codename]?.length!==0
                    ) ;

                    // FALLBACK CHOOSING CURRENT CATEGORY
                    this.categories && !this.categories.find(item=>item.codename === this.selectedCategoryCode)
                        && (this.setSelectedCategoryCode(this.categories.find(item=>item.codename === catBackUp)?catBackUp:(this.categories.length?this.categories[0]?.codename:Object.keys(resp.data.data.categoryProducts)[0])));
                    
                    //!this.selectedCategoryCode && (this.selectedCategoryCode = Object.keys(resp.data.data.categoryProducts)[0]);
                    
                    this.categoryPage!==1 && (
                        resp.data.data.categoryProducts[this.selectedCategoryCode] = 
                            toJS(this.itemListAll[this.shopId][this.selectedCategoryCode]).concat(resp.data.data.categoryProducts[this.selectedCategoryCode])); 
                    this.categoryPageCount = typeof resp.data.data.pagesCount !== 'undefined' ? resp.data.data.pagesCount : 0;
                    //!this.itemListAll && (this.itemListAll[this.shopId] = {});  
                    this.itemListAll[this.shopId] = { ...(this.itemListAll && this.itemListAll[this.shopId]?this.itemListAll[this.shopId]:{}), ...resp.data.data.categoryProducts };

                } else (this.categories = this.categories.filter(item => item.codename !== catTarget)) 
                        && (this.selectedCategoryCode = catBackUp);   
            }
            this.isProductsLoading = false;
            //this.categories.findIndex(item => item.codename === this.selectedCategoryCode) === -1 && (this.selectedCategoryCode = null);
            this.selectedSubCategoryCodename = sub_cat_codename;
        })

       
    }

    @action
    getProductsAssociated = async () => {
        const url = this.getApi('get-products');
        const form = new FormData();
        form.append('shopId', this.shopId);
        form.append('categoryCodename', '');
        form.append('orderType', this.orderData?.orderType??'delivery');

        let shopProductsAssociatedIds = this.checkAssociatedProductsInCart();

        shopProductsAssociatedIds.forEach((product,p_idx) => {
            form.append(`cartProductIds[${p_idx}]`, product);
        })
        // form.append('cartProductIds', JSON.parse(shopProductsAssociatedIds));

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });
        // console.log("getProductsAssociated => getItemList resp:", resp);
        this.associatedProducts = resp?.data?.data?.cartProducts;
        
        return resp?.data?.data;
    }

    @action
    getApiResp_SetShop = (lng = null) => {
        this.resetShopError();
        if (this.isShopLoading) return;
        //if (this.storeOpened) return; //It needs to close store before 
        this.isShopLoading = true;
        const form = new FormData();
        this.shopId && form.append('shopId', this.shopId);
        this.userId && form.append('userId', this.userId);
        this.shopCode && form.append('code', this.shopCode);
        form.append('source', 'site');
        this.orderData && form.append('orderType', this.orderData?.orderType??'delivery');
        //const resp = await
        axios({
            method: 'post',
            url: this.getApi('set-shop'),
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
                'Bearer': this.userToken
            },
        }).then(resp => {         
            if(['VALIDATION_FAILED', 'SHOP_NOT_FOUND', 'QUERY_ERROR', 'BLOCKED_USER'].includes(resp.data.errorCode)) {
                this.setShopError(resp.data.errorCode);
                //console.log("SET_SHOP ERROR::", resp.data.errorCode);
                this.closeStore();
                //return false
            } else if(resp.data.error != null) {
                this.isMaintenance = true;
                console.log("SET_SHOP ERROR::", resp.data.error);
                //return true;
            } else {
                this.isMaintenance = false;
                this.setShopId(resp.data.data.shop.id);
                this.setShopData(resp.data.data);
                this.setShopName(resp.data.data.shop.name);
                this.setShopLatLng()
                this.setCategories(resp.data.data.shop.categories);
                const catIndex = this.categories?.findIndex(item=>item.codename===this.selectedCategoryCode);
                this.getItemList(catIndex<0?0:catIndex, true);
                // setTimeout(() => {
                //     this.storeOpened = true;
                // }, 1000)

                this.setCart(); //added for single store ******
            }
            this.isShopLoading = false;
        })
    }



    @action
    getValidateCode = async (shopId, code) => { //for business store!
        const url = this.getApi('validate-code');
        var form = new FormData();

        form.append('shopId', shopId);
        form.append('code', code);
        
        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });

        if(resp.data.error != null) {
            // console.log("validate-code ERROR::", resp.data.error);
        } else {
            // console.log("validate-code RESP::",resp)
        }

        return resp.data;
    }

    ////NOT REMOVE!
    // @action
    // getApiResp = async () => {
    //     console.log("IN getApiResp !!!")
    //     var form = new FormData();
    //     form.append('shopId', my_env.shopId);

    //     const url = this.getApi('set-shop');
    //     // console.log("url:", url);
    //     await axios({
    //         method: 'post',
    //         url,
    //         data: form,
    //         headers: {
    //             'content-type': `multipart/form-data; boundary=${form._boundary}`,
    //         },
    //     }).then((response) => {
    //         // console.log(response);
    //         this.apiResp = response;
    //         this.setData();
    //         return response;
    //     }, (error) => {
    //         this.isMaintenance = true;
    //         if(my_env.debug){
    //             console.log("error:", error);
    //         }
    //     });
    //     // console.log("shop:", this.apiResp);
    //     return this.apiResp;
    // }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateUserParams = ( required = [], localState ) => {
        const errors = [];
        if(!this.userParams.country) this.setUserParams('country', this.shopData.shop.country);
        const userParams = localState; // this.userParams;
        const phoneUtil = PhoneNumberUtil.getInstance();
        for (let param in userParams) {
            if(required.includes(param) && (!userParams[param] || userParams[param].trim() === '')) {
                errors.push(param);
            }
            else if(param === 'email' && userParams[param].trim() !== '' && !this.validateEmail(userParams[param].trim())){
                errors.push(param);
            }
            else if(param === 'phone'){
                if(userParams[param].trim().includes('00000000')) {
                    this.setUserParams('country', {
                        phoneCode: '+10000000000', 
                        alpha2Code: 'us',
                    });
                    continue;
                }
                const countries = {972:'il', 1:'us'};
                if (userParams[param].trim().length < 7) {
                    errors.push(param);
                    continue;
                }
                // if entered more that 10 digits - we check it as international number, if less - as local
                const number = phoneUtil.parse(userParams.phone, userParams.phone.length>10 ? 'IL' : (/*this.shopData.country?.codename ?? */'IL'));
                const availableCodes = Object.keys(countries).concat(this.shopData.country?.phonePrefixes ?? []);               
                if(
                    availableCodes.reduce((acc, code) => acc || !phoneUtil.isValidNumber(number, countries[code]), false)
                    //|| phoneUtil.getNumberType(number) !== PhoneNumberType.MOBILE
                    || !availableCodes.includes(number.getCountryCode().toString()) 
                    //&& !(/^\d+$/.test(userParams[param])) && param.length !== 10
                ) {
                    errors.push(param);
                    console.log({
                        message: 'Phone number is not valid',
                        isNotValidNumber: !number || availableCodes.reduce((acc, code) => acc || !phoneUtil.isValidNumber(number, countries[code]), false),
                        isMobile: !number || phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE,
                        isCountryCodeAcceptable: !number || availableCodes.includes(number?.getCountryCode().toString()),
                    })
                } else {
                    // this.setUserParams('phone', phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL));
                    // console.log(' phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL)', phoneUtil.format(number, PhoneNumberFormat.MOBILE));
                    this.setUserParams('country', {
                        phoneCode: '+' + number.getCountryCode(), 
                        alpha2Code: phoneUtil.getRegionCodeForNumber(number).toLowerCase()
                    });
                }
            }
            // else if(param === 'streetNumber' && !(/^\d+$/.test(userParams[param]))){
            //     errors.push(param);
            // }
        }

        return errors;
    }

    @action
    setUserParams = (param, value) => {
        if(param === 'country' && value.phonePrefixes?.length){
            value = { phoneCode: value.phonePrefixes[0] }
        }

        this.userParams[param] = value;
    }

    @action
    setCreditParams = (param, value) => {
        this.creditParams[param] = value;
    }

    @action
    registerUser = async () => {
        this.setWrongSMSCode(false);
        const url = this.getApi('upsert-user');
        var form = new FormData();
        form.append('shopId', this.shopId);

        for (let param in this.userParams) {
            if(param === 'country' || param === 'phone') continue;
            if(typeof this.userParams[param] === 'undefined') continue;
            form.append(param, this.userParams[param]);
        }
        form.append('phone', this.userParams.phone);
        form.append('userId', this.userId);

        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });

        if(resp.data.errorCode == 'BLOCKED_USER'){
            this.setBlockedUser();
        }

        // if(resp.data.error != null) {
        //     // console.log("registerUser ERROR::", resp.data);
        // } else {
        //     // console.log("registerUser RESP::", resp.data);
        // }
        return resp.data;
    }

    @action
    verifyPhone = async (code) => {
        const url = this.getApi('verify-phone');
        const form = new FormData();
        //const phoneCode = !['undefined'].includes(this.userParams.country?.phoneCode) ? this.userParams.country?.phoneCode : ''; 
        form.append('phone', this.userParams.phone);
        form.append('code', code);

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
            },
        });
        if(resp.data.data === null) {
            // console.log("verifyPhone ERROR::", resp.data);
            if(resp.data.errorCode != '0'){
                this.setWrongSMSCode(true);
            }
            if(resp.data.errorCode == 'BLOCKED_USER'){
                this.setBlockedUser();
            }
            return false;
        } 
        else {
            // console.log("verifyPhone RESP::", resp.data);
            this.userToken = resp.data.data.token;
            this.userId = resp.data.data.id;
            this.shopId = resp.data.data.shopId;
            this.setWrongSMSCode(false);
            return true;
        }
    }

    @action
    unsetUserToken = () => {
        this.userToken = null;
    }
    
    @action
    getShopsResp = () => {
        if(this.isShopsLoading) return;
        this.isShopsLoading = true;
        const url = my_env.url + '/api/get-shops'; //this.getApi('get-shops');
        axios({
            method: 'post',
            url,
        }).then(resp => {
            if(resp.data.error != null) {
                console.log("get-shops ERROR::", resp.data);
            } 
            else {
                this.shopsData = resp.data.data;
                if(resp.data.data.shops && !resp.data.data.shops.length){ //one shop!
                    //this.setShopsData({shops: []})
                    this.setShopId(1);
                    // this.shopsData = {shops:[]};
                    this.openStore(this.shopId);
                    // this.shopId = 1;
                    //this.isSingleStore = true;
                }
                // else{
                //     //this.setShopsData(resp.data.data);
                //     this.shopsData = resp.data.data;
                //     //this.isSingleStore = false;


                //     // this.shopId = null;
                //     //this.shopsData.shops = this.shopsData.shops.map((item) => {item.isPriceOrderType = 1; return item; }); // @todo remove it
                // }


            }
           
            this.isShopsLoading = false; 
           
        });
    }

    checkCouponResp = async (couponCode) => {
        const url = this.getApi('check-coupon');
        var form = new FormData();
        form.append('coupon', couponCode);
        form.append('shopId', this.shopId);
        this.userId && form.append('userId', this.userId);
        !this.orderData?.orderType && this.preOrderType && form.append('orderType', this.preOrderType) 
        this.orderData?.orderType && form.append('orderType', this.orderData.orderType);

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
                ...(this.userToken?{'Bearer': this.userToken}:{}),
            },
        });

        if(resp.data.data === null) {
            // console.log("check-coupon ERROR::", resp.data);
        }
        else {
            // console.log("check-coupon RESP::", resp.data);
            this.setCoupon(couponCode, (typeof resp.data.data.couponType == 'undefined'? resp.data.data.discount : (resp.data.data.couponType === 'money' ? resp.data.data.sum : resp.data.data.discount)), resp.data.data.couponType??(resp.data.data.product != null ? 'product' : 'percent'));
        }

        return resp.data;
    }


    @action
    getOrderHistoryResp = async () => {
        
        if(this.historyPage < 0) return false;

        const url = this.getApi('get-order-history');
        var form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('pageSize', 12);

        const page = this.historyPage;
        form.append('page', page);
        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        }).then(resp => {
            if(resp?.data?.errorCode === '0') {
                this.setOrdersHistory(resp.data.data.orders);
            }
            return resp;
        });
        
        if(resp.data.error != null) {
            // console.log("getOrderHistoryResp ERROR::", resp.data);
        } else {
            if(resp?.data?.data?.orders?.length){
                this.historyPage = page + 1;
            } else {
                this.historyPage = -1;
            }
        }
        return resp.data;
    }

    //@action 
    getOrderDelivery = async (orderId, etaUpdate = false) => {
        const url = this.getApi('get-order-delivery');
        var form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('orderId', orderId);
        if(etaUpdate) form.append('eta_update', etaUpdate);

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers:  {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
                'Bearer': this.userToken
            }
        });

        if(!resp.data.error) {
            return resp.data.data;
        }
        return false;
    }
    
    @action
    getOrderHistoryTimeResp = async () => {
        const url = this.getApi('order-history-times');
        var form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });

        if(resp.data.errorCode === '0' && resp.data.data && resp.data.data.times) {
            // console.log("getOrderHistoryTimeResp RESP::", resp.data);
            return resp.data.data.times;
        } else {
            // console.log("getOrderHistoryTimeResp ERROR::", resp.data);
            return null;
        }
        
    }

    @action
    callback(data, error){
        // consume data
        if (error) {
            console.error(error);
            return;
        }
        // console.log("data:", data);
        return data;
    };

    @action
    async request(retries, callback, params) {
        // console.log('params:',params)
        const res = await axios({
            ...params
        })
        .then(async response => {
            // request successful
            if(response.data.errorCode === '0') {
                // server done, deliver data to script to consume
                return callback(response);
            }
            else {
                // server not done yet
                // retry, if any retries left
                if (retries > 0) {
                    await this.request(--retries, callback);
                }
                else {
                    // no retries left, calling callback with error
                    return callback([], "out of retries");
                    
                }
            }
        }).catch(async error => {
            // ajax error occurred
            // would be better to not retry on 404, 500 and other unrecoverable HTTP errors
            // retry, if any retries left
            if (retries > 0) {
                await this.request(--retries, callback);
            }
            else {
                // no retries left, calling callback with error
                return callback([], error);
            }
        });

        return res;
    }

    @action
    makePreorderResp = async () => {
        const url = this.getApi('make-preorder');
        const form = new FormData();

        let amountToPay = this.cartPrice ?? 0;

        if(this.multipassCards.length > 0) {
            let multipassTotalAmount = this.multipassCards.reduce((total, card) => total + card.amount, 0);
            let multipassTotalBenefits =  this.multipassCards.reduce((total, card) => total + card.benefits, 0);
            let multipassTotalDiscounts =  this.multipassCards.reduce((total, card) => total + card.discounts, 0);
            let totalByMultipass = multipassTotalAmount + multipassTotalBenefits + multipassTotalDiscounts;
            amountToPay = amountToPay - totalByMultipass;
        }
        
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('sum', amountToPay);

        const isExternalComax = this.shop.externalTypeCodename === 'comax' ? 'comax' : '';

        const type = isExternalComax || this.shop.paymentSystem || 
            (['Israel'].includes(this.shopData.country.name) ? 'zcredit' : 'stripe');

        //['stripe', 'comax'].includes(type) && 

        const coupon = this.getCoupon();
        if(coupon) form.append('coupon', coupon.code);

        const { pickupData, deliveryData, orderType } = this.orderData;
        
        form.append('orderType', orderType ?? 'delivery');

        const appointment = this.shop['withoutFuture_' + (orderType == 'sit' ? 'pickup' : orderType)]  
            ? { date: this.getTimeStamp(), from: this.getFormatedTimeHHMM(), to: this.getFormatedTimeHHMM() } 
            : (this.orderData?.isDelivery ? deliveryData : pickupData);
        
        form.append('deliveryDate', appointment.date);
        form.append('deliveryFrom', appointment.from);
        form.append('deliveryTo', appointment.to);

        const products = this.getProductsParam();
        form.append('products', products);
        let thisPaymentType = (!this.shop.withoutPrices && this.shop.isBusiness) ? 'business' : (this.otherPaymentType ? this.otherPaymentType : (this.orderData.isCredit ? 'credit' : 'cash'));

        if(this.otherPaymentType != 'multipass' && this.multipassCards.length > 0) {

            const cardsDetailsString = this.multipassCards.map(item => {
                const benefitsProductsString = item.benefitsProducts
                    .map(product => `${product.Id},${product.leftToPayProduct}`)
                    .join(';') || '0';
                return `${item.amount}|${item.benefits}|${item.discounts}|${item.multipassCardNumber}|0|${benefitsProductsString??0}`;
            }).join('||');
            const multipassAmount = this.multipassCards.reduce((total, card) => total + card.amount + card.benefits + card.discounts, 0);

            let paymentTypesSplit = [];
            paymentTypesSplit.push([`paymentTypesSplit[0][codename]`, thisPaymentType])
            paymentTypesSplit.push([`paymentTypesSplit[0][sum]`, this.cartPrice-multipassAmount])
            paymentTypesSplit.push([`paymentTypesSplit[1][codename]`, 'multipass'])
            paymentTypesSplit.push([`paymentTypesSplit[1][sum]`, multipassAmount])
            paymentTypesSplit.push([`paymentTypesSplit[1][cardParams]`, cardsDetailsString])
            
            // this.setOtherPaymentType('split');
            thisPaymentType = 'split';

            paymentTypesSplit.forEach(paymentTypesSplit_row => {
                form.append(paymentTypesSplit_row[0], paymentTypesSplit_row[1]);
            })
        } else if(this.otherPaymentType == 'multipass') {
            const multipassAmount = this.multipassCards.reduce((total, card) => total + card.amount + card.benefits + card.discounts, 0);
            form.append('lefttopay', parseFloat(this.cartPrice-multipassAmount).toFixed(2));
        }
        products.forEach(product => {
            form.append(product[0], product[1]);
        })
        form.append('source', "site");
        form.append('onesignalId', ''); //CHECK THIS!
        form.append('paymentType', thisPaymentType);

        
        form.append('type', thisPaymentType == 'cash'? 'cash' : type);
        this.cardNumber && this.otherPaymentType && form.append('paymentCard', this.cardNumber);
        this.orderData.branch && form.append("branchId", this.getBranchId());
        if(this.orderData.isCredit){
            // console.log("uniqueId:", this.creditUniqueId)
            form.append('UniqueID', this.creditUniqueId);
        }

        if(this.orderData.isDelivery){
            const { deliveryData } = this.orderData;
            form.append('deliveryComment', deliveryData.deliveryGuyComment);
            form.append('comment', deliveryData.orderComment);
            form.append('deliveryCost', this.deliveryCost);
            form.append('distance', this.orderData.distance);
            form.append('deliveryLat', this.deliveryLatLng ? this.deliveryLatLng.lat : null);
            form.append('deliveryLon', this.deliveryLatLng ? this.deliveryLatLng.lng : null);
            if (this.deliveryType) {
                form.append('deliveryType', this.deliveryType.codename);
                form.append('deliveryId', this.deliveryType.deliveryId);
            }
        }
        else{ /// pickup
            const { pickupData } = this.orderData;
            form.append('deliveryCost', null);
            form.append('comment', pickupData.orderComment);
            form.append('distance', 0);
            pickupData.pickupDelayTime && form.append('delay', parseInt(pickupData.pickupDelayTime));
        }
        
        if(this.orderData.doAnotherOrder) {
            form.append('newOrder', 1);
        }

        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }
        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });

        if(resp.data?.errorCode == 0) {
            console.log({type, data: resp.data.data})
            switch(type) {
                case 'zcredit':
                    this.setCreditUniqueId(resp.data.data.UniqueId);
                    break;
                case 'pelecard':
                    this.setCreditUniqueId(resp.data.data.UniqueId);
                    this.setSessionUrl(resp.data.data.link);
                    break;
                case 'stripe':
                    this.shopData.shop.paymentApiKey = resp.data.data.paymentApiKey;
                    this.setCreditUniqueId(resp.data.data.UniqueId);
                    this.setCreditParams('token', resp.data.data.clientSecret);
                    break;
                case 'comax':
                    this.setCreditUniqueId(resp.data.data.UniqueId);
                    this.setSessionUrl(resp.data.data.link);
                    break;
                default:
                    break;
            }
        } else {
            this.setCreditUniqueId('');
        }

        return resp;
    }

    @action
    checkPreorderResp = async () => {
        const url = this.getApi('check-preorder');
        const form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('sum', this.cartPrice);
        form.append('UniqueID', this.creditUniqueId);
        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });

        // console.log("checkPreOrder only resp:::", resp);
        return resp;

    }

    @action
    checkApproveOrderResp = async (orderId) => {
        const url = this.getApi('customer-approve-order');
        const form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        form.append('orderId', orderId);
        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });

        // console.log("checkPreOrder only resp:::", resp);
        return resp;

    }

    @action
    createSessionResp = async () => {
        this.setSessionUrl(''); 

        const url = 'https://pci.zcredit.co.il/webcheckout/api/WebCheckout/CreateSession'
        // const key = process.env.REACT_APP_CREATE_SESSION_API_KEY;
        const key = this.shop.paymentApiKey;

        this.calcTotalCartPrice();
        let amountToPay = this.cartPrice ?? 0;

        if(this.multipassCards.length > 0) {
            let multipassTotalAmount = this.multipassCards.reduce((total, card) => total + card.amount, 0);
            let multipassTotalBenefits =  this.multipassCards.reduce((total, card) => total + card.benefits, 0);
            let multipassTotalDiscounts =  this.multipassCards.reduce((total, card) => total + card.discounts, 0);
            let totalByMultipass = multipassTotalAmount + multipassTotalBenefits + multipassTotalDiscounts;
            amountToPay = amountToPay - totalByMultipass;
        }

        const cartItems = [{
            "Amount": amountToPay ? amountToPay.toString() : '0',
            "Currency": 'ILS',
            // "Quantity": this.getCart().cartItemList.length,
            "Quantity": 1,
            "Name": 'הזמנה',
            //"Description": "My Item description , comes below the name" , 
            //"Image": "https://www.z-credit.com/site/wp-content/themes/z-credit/img/decisions/decision2.png" ,
            "IsTaxFree":  "false",
        }]

        const data =  {
            "Key": key,
            "Local": "He",
            "UniqueId": this.creditUniqueId,
            "SuccessUrl": `${window.location.origin}/thanks.html`,
            "CancelUrl": `${window.location.origin}/cancel.html`,
            "CallbackUrl": `${this.getApi('callback-preorder-c575a054e658c0c1b9b7d42856bf7f5b')}`,
            "PaymentType": /*this.shop.directPayment ? "validate" : */"authorize",
            "CreateInvoice": "false",
            "AdditionalText": "",
            "ShowCart": "false",
            "CardsIcons": {
                "ShowVisaIcon": false,
                "ShowMasterCardIcon": false,
                "ShowDinersIcon": false,
                "ShowIsracardIcon": false,
                "ShowAmericanExpressIcon": false,
            },
            "Installments": 0,
            "Customer": {
                "Email": this.userParams.email,
                "Name": this.userParams.name ,
                "PhoneNumber":  this.userParams.phone,
                "Attributes": {
                    "HolderId":  'required',
                    "PhoneNumber":  'required' ,
                    "Name":  'optional' ,
                    "Email":  'optional'
                }
            },
        "CartItems": cartItems
        }

        //update payments number
        if(this.shop.maxPayments > 1){
            data["Installments"] = {
                Type: "regular" , 
                MinQuantity: "1",
                MaxQuantity: this.shop.maxPayments
            };
        }

        const headers = {
            'content-type': 'application/json; charset=utf-8'
        }

        let resp = false;
        try {
            resp = await axios({
                method: 'post',
                url,
                data,
                headers
            });
        } catch(error) {
            //console.log(error)
        }

        if(!resp || !resp.data) {
            this.setSessionUrl('ERROR'); 
            console.log(resp, 'err_1');
        } else {
            if(resp.data.HasError) {
                // console.log("createSessionResp ERROR::", resp.data);
                this.setSessionUrl('ERROR');
                console.log(resp, 'err_2');
            } else {
                // console.log("createSessionResp RESP::", resp.data);
                this.setSessionUrl(resp.data.Data.SessionUrl);
            }
        }

    }

    @action
    getDeliveryPriceResp = async (sum) => {
        const url = this.getApi('get-delivery-price');
        const form = new FormData();
        form.append('shopId', this.shopId);
        const { lat, lng } = this.getDeliveryLatLng();
        form.append('deliveryLat', lat);
        form.append('deliveryLon', lng);
        form.append('source', 'site');
        form.append('sum', sum);
        if (true || this.orderData.isCredit) {
            form.append('paymentType', 'credit');
            form.append('city', this.userParams.city);
            form.append('streetName', this.userParams.streetName);
            form.append('streetNumber', this.userParams.streetNumber);
            form.append('deliveryDate', this.shop.withoutFuture_delivery ? this.getTimeStamp() : this.orderData.deliveryData.date);
            form.append('deliveryFrom', this.shop.withoutFuture_delivery ? this.getFormatedTimeHHMM() : this.orderData.deliveryData.from);
            form.append('deliveryTo', this.shop.withoutFuture_delivery ? this.getFormatedTimeHHMM() : this.orderData.deliveryData.to);
        }
        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        }).then((resp) => {
            if (resp.data.errorCode === "0" && Array.isArray(resp.data.data.deliveries)) {
                //resp.data.data.deliveries?.push({codename: 'yango', name: 'יאנגו', price: 45});
                resp.data.data.deliveries?.sort((a,b)=>a.price-b.price)
                this.deliveries=resp.data.data.deliveries
            } else {
                this.deliveries=[];
                return false;
            }
            return resp;
        });

        return resp;
    }

    getTaxes = async () => {
        const products = this.getProductsParam();
        const form = new FormData();
        const coupon = this.getCoupon();

        products.forEach(product => {
            form.append(product[0], product[1]);
        })

        form.append('source', "site");
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);
        //form.append('onesignalId', ''); //CHECK THIS!
        form.append('orderType', this.orderData?.orderType??'delivery');;
        form.append('paymentType', (!this.shop.withoutPrices && this.shop.isBusiness) ? 'business' : (this.otherPaymentType ? this.otherPaymentType : (this.orderData.isCredit ? 'credit' : 'cash')));
        this.cardNumber && this.otherPaymentType && form.append('paymentCard', this.cardNumber);
        form.append('coupon', coupon ? coupon.code : ''); //CHECK THIS!
        this.orderData.branch && form.append("branchId", this.getBranchId());
        if(this.orderData.isDelivery){
            form.append('deliveryCost', this.deliveryCost);
            if (this.deliveryType) {
                form.append('deliveryType', this.deliveryType.codename);
                form.append('deliveryId', this.deliveryType.deliveryId);
            }
        }
        return axios({
            method: 'post',
            url: this.getApi('get-taxes'),
            data: form,
            headers: {
                'content-type': `multipart/form-data; boundary=${form._boundary}`,
                'Bearer': this.userToken
            }
        }).then((resp) => {
            console.log(resp.data.data.tax)
            if(resp.data.errorCode === '0') {
                this.setOrderData('taxes', resp.data.data.tax);
                return resp.data.data.tax;
            }
        });
    }

    @action
    checkTime = async () => {

        if(typeof (this.shop.hasCheckTime) !== 'undefined' && this.shop.hasCheckTime == '1') {
            const { pickupData, deliveryData } = this.orderData;
            const url = this.getApi('check-time');
            const coupon = this.getCoupon();
            var form = new FormData();

            form.append('shopId', this.shopId);
            form.append('orderType', this.orderData?.orderType??'delivery');
            if(coupon) form.append('coupon', coupon.code);
            if(this.userId && this.userToken) form.append('userId', this.userId);

            if(this.orderData?.isDelivery){
                form.append('deliveryDate', this.shop.withoutFuture_delivery ? this.getTimeStamp() : deliveryData.date);
                form.append('deliveryFrom', this.shop.withoutFuture_delivery ? this.getFormatedTimeHHMM() : deliveryData.from);
                form.append('deliveryTo', this.shop.withoutFuture_delivery ? this.getFormatedTimeHHMM() : deliveryData.to);
            }
            else{ /// pickup
                form.append('deliveryDate', this.shop.withoutFuture_pickup ? this.getTimeStamp() : pickupData.date);
                form.append('deliveryFrom', this.shop.withoutFuture_pickup ? this.getFormatedTimeHHMM() : pickupData.from);
                form.append('deliveryTo', this.shop.withoutFuture_pickup ? this.getFormatedTimeHHMM() : pickupData.to);
            }

            let headers = {
                'content-type': `multipart/form-data; boundary=${form._boundary}`
            }

            if(this.userId && this.userToken) headers.Bearer = this.userToken;

            const resp = await axios({
                method: 'post',
                url,
                data: form,
                headers
            });

            return resp.data;
        } else {
            return {
                'errorCode': '0'
            };
        }

    }

    @computed
    get ShopsListType(){
        return ['toskana', 'fast', 'scooty', 'bafalo']
                .find(item => this.my_env.url.indexOf(item) >=0)
            ??((process?.env.REACT_APP_CUSTOM_BG == 'true') ? 'custom':'default');
    }

    @computed 
    get orderTypesOvertime(){
        if (!this.shop) return {};
        const { withoutFuture_delivery, withoutFuture_pickup, pickupTimes, deliveryTimes, orderTypes } = this.shop;
        const checkedTypes = {};
        orderTypes?.forEach((item)=>{
            let timeSlots = item==='delivery'?deliveryTimes:pickupTimes;
            let future = item==='delivery'?withoutFuture_delivery:withoutFuture_pickup;
            let { mode } = this.getCloseShifts(timeSlots, future);
            checkedTypes[item] = mode===0 || mode===3;
        })
        return checkedTypes;
    }

    @computed
    get workingShiftSign(){
        const workingShift = this.getCloseShifts(this.shop.workingTimes);
        return (
            workingShift.current?.from ? (` העסק שלנו פתוח מ-${workingShift.current.from} עד ${workingShift.current.to}. `) : 
            (
                workingShift.next?.from ? 
                (
                    (workingShift.mode === 4) 
                        ? `העסק שלנו יפתח ב-${workingShift.next.from}. `
                        : workingShift.next && `העסק שלנו יפתח ${workingShift.nextDate.toLocaleDateString('he-IL')} ב-${workingShift.next.from}. `
                ) : null
            )
        );
    }

    @computed
    get takeawaySign(){
  
        const { withoutFuture_delivery, withoutFuture_pickup, pickupTimes, deliveryTimes, orderTypes } = this.shop;

        const deliveryShifts = orderTypes.includes('delivery')?this.getCloseShifts(deliveryTimes, withoutFuture_delivery):{mode: 0};
        const pickupShifts = orderTypes.includes('pickup')?this.getCloseShifts(pickupTimes, withoutFuture_pickup):{mode:0};

        // const workModes = [
        //     'ללא',
        //     'יש להיום ל שעה עכשיו',
        //     'עם הזמנות עתידיות לא מקבל הזמנות להיום',
        //     'ללא הזמנות עתידיות לא מקבל הזמנות להיום',
        //     'ללא הזמנות עתידיות מקבל הזמנות להיום אבל לא בשעה עכשיו '
        // ];

        const deliveryTime = deliveryShifts.next?.from??'';
        const pickUpTime  = pickupShifts.next?.from??'';
        const messages = [
            [`משלוח ואיסוף לא זמינים כעת`,	`שירות המשלוחים לא פעיל, אך ניתן לבצע הזמנה באיסוף עצמי`,	`ניתן לבצע הזמנה עתידית באיסוף עצמי`,	`משלוח ואיסוף לא זמינים כעת`, `שירות המשלוחים לא פעיל, אך ניתן לבצע הזמנה באיסוף עצמי החל מהשעה ${pickUpTime}`],
            [ "", "", "", "", ""],
            [`ניתן לבצע משלוח להזמנה עתידית`,	`ניתן לבצע משלוח להזמנה עתידית, או לבצע הזמנה באיסוף עצמי מיידי`,	`משלוח ואיסוף זמינים להזמנות עתידיות בלבד`,	`משלוח זמין להזמנות עתידיות בלבד`, `ניתן לבצע משלוח להזמנה עתידית, או לבצע הזמנה באיסוף עצמי החל מהשעה ${pickUpTime}` ],
            [``, `שירות המשלוחים לא פעיל כעת, אך ניתן לבצע הזמנה באיסוף עצמי`, `ניתן לבצע הזמנה עתידית באיסוף עצמי`, ``,	`שירות המשלוחים לא פעיל, אך ניתן לבצע הזמנה באיסוף עצמי החל מהשעה ${pickUpTime}`],
            [`שירות המשלוחים ישוב לפעילות החל מהשעה ${deliveryTime}`,	`ניתן לבצע הזמנות באיסוף עצמי מיידי. שירות המשלוחים ישוב לפעילות החל מהשעה ${deliveryTime}`,	`שירות המשלוחים ישוב לפעילות החל מהשעה ${deliveryTime}`,	`שירות המשלוחים ישוב לפעילות החל מהשעה ${deliveryTime}`,	`שירות המשלוחים ישוב לפעילות החל מהשעה ${deliveryTime}`]
        ];

        return (messages[deliveryShifts.mode][pickupShifts.mode].length?(messages[deliveryShifts.mode][pickupShifts.mode] + '.'):'');
    }

    getCloseShifts = (shifts, withoutFuture=false) => {
        if (!shifts.length) return {mode:0};
        const nextWorkingDate = new Date();
        // @todo make server send numbers of week days
        const fullWeek = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        if(shifts[0]?.date){ // for those who has exact days in their objects take only first two days
            const limitDate = new Date(shifts[0].date*1000);
            limitDate.setHours(0,0,0,0);
            limitDate.setDate(limitDate.getDate()+1);
            shifts = shifts.filter(shift=>!shift.date || (new Date(shift.date*1000)).setHours(0,0,0,0)<limitDate);
        }
        const wT = fullWeek.map(weekday=>shifts.filter(item=>item.weekday === weekday));
        const timeOptions = {hour: '2-digit', minute:'2-digit'};
        const today = wT[nextWorkingDate.getDay()].filter(shift=>shift.to>nextWorkingDate.toLocaleTimeString('he-IL', timeOptions));
        today.sort((a,b) => a.from > b.from);

        let nextWorkingDay=[];
        let currentShift;
        let nextShift;
        if(today.length) {
            currentShift = today.find(shift=>shift.from < nextWorkingDate.toLocaleTimeString('he-IL', timeOptions) && shift.to>nextWorkingDate.toLocaleTimeString('he-IL', timeOptions));
            if(!currentShift) nextShift = today.find(shift=>shift.from>nextWorkingDate.toLocaleTimeString('he-IL', timeOptions));
        }
      
        if(!currentShift && !nextShift  && wT.some(day => day.length)) { 
            do {
                nextWorkingDate.setDate(nextWorkingDate.getDate() + 1);
                nextWorkingDay=wT[nextWorkingDate.getDay()];
            } while(nextWorkingDay.length===0)
            if(nextWorkingDay.length>0) {
                nextWorkingDay.sort((a,b) => a.from>b.from);
                nextShift=nextWorkingDay[0];
            }
        }

        const mode = (!currentShift && !nextShift)?0:(currentShift?1:(today.length?4:(!withoutFuture?2:3)));

        return {current: currentShift, next: nextShift, mode: mode, nextDate: nextWorkingDate};
    }

    @action
    getUserBudgetResp = async () => {
        
        if(this.historyPage < 0) return false;

        const url = this.getApi('user-budget');
        var form = new FormData();
        form.append('userId', this.userId);
        form.append('shopId', this.shopId);

        const headers = {
            'content-type': `multipart/form-data; boundary=${form._boundary}`,
            'Bearer': this.userToken
        }

        const resp = await axios({
            method: 'post',
            url,
            data: form,
            headers
        });
        
        if(resp?.data?.errorCode === '0') {
            this.setUserBudget(resp.data.data.budget);
            return true;
        } else {
            this.setUserBudget(null);
            return false;
        }
    }
}


export default MyShopApiStore